export const ACTIVATION_PATH = "activation"
export const ACTIVATION_FRAGMENT = 'code'

export const HTTPS_SCHEME = 'https'
export const BAYERN_PRODUCTION_ID = 'bayern.ehrenamtskarte.app'
export const BAYERN_STAGING_ID = 'staging.bayern.ehrenamtskarte.app'
export const NUERNBERG_PRODUCTION_ID = 'nuernberg.sozialpass.app'
export const NUERNBERG_STAGING_ID = 'staging.nuernberg.sozialpass.app'
export const KOBLENZ_PRODUCTION_ID = 'koblenz.sozialpass.app'
export const KOBLENZ_STAGING_ID = 'staging.koblenz.sozialpass.app'

export const QUERY_PARAM_NAME = 'Name'
export const QUERY_PARAM_BIRTHDAY = 'Geburtsdatum'
export const QUERY_PARAM_KOBLENZ_REFERENCE_NUMBER = 'Referenznummer'
// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file card.proto (syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum BavariaCardType
 */
export enum BavariaCardType {
  /**
   * @generated from enum value: STANDARD = 0;
   */
  STANDARD = 0,

  /**
   * @generated from enum value: GOLD = 1;
   */
  GOLD = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(BavariaCardType)
proto3.util.setEnumType(BavariaCardType, "BavariaCardType", [
  { no: 0, name: "STANDARD" },
  { no: 1, name: "GOLD" },
]);

/**
 * @generated from enum NuernergPassIdentifier
 */
export enum NuernergPassIdentifier {
  /**
   * @generated from enum value: passNr = 0;
   */
  passNr = 0,

  /**
   * @generated from enum value: passId = 1;
   */
  passId = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(NuernergPassIdentifier)
proto3.util.setEnumType(NuernergPassIdentifier, "NuernergPassIdentifier", [
  { no: 0, name: "passNr" },
  { no: 1, name: "passId" },
]);

/**
 * @generated from message RegionExtension
 */
export class RegionExtension extends Message<RegionExtension> {
  /**
   * Using int32 instead of uint32, even though we do not expect negative values.
   * The reason is that int32 are better supported in GraphQL and SQL.
   *
   * @generated from field: optional int32 regionId = 1;
   */
  regionId?: number;

  constructor(data?: PartialMessage<RegionExtension>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "RegionExtension";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "regionId", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegionExtension {
    return new RegionExtension().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegionExtension {
    return new RegionExtension().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegionExtension {
    return new RegionExtension().fromJsonString(jsonString, options);
  }

  static equals(a: RegionExtension | PlainMessage<RegionExtension> | undefined, b: RegionExtension | PlainMessage<RegionExtension> | undefined): boolean {
    return proto3.util.equals(RegionExtension, a, b);
  }
}

/**
 * @generated from message BirthdayExtension
 */
export class BirthdayExtension extends Message<BirthdayExtension> {
  /**
   * Days since the birthday (calculated from 1970-01-01).
   * All values of this field are valid, including the 0, which indicates that the birthday is on 1970-01-01.
   * We choose sint32 here, as it encodes negative numbers more efficiently: For birthdays between 1900 and 2050, the
   * BirthdayExtension message takes up to 4 bytes when encoded binary, whereas using int32 would require up to 11 bytes
   * for birthdays before 1970 and up to 4 bytes for birthdays between 1970 and 2050.
   * If you want to specify that the card does not have an associated birthdate, then do not set the BirthdayExtension.
   *
   * @generated from field: optional sint32 birthday = 1;
   */
  birthday?: number;

  constructor(data?: PartialMessage<BirthdayExtension>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "BirthdayExtension";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "birthday", kind: "scalar", T: 17 /* ScalarType.SINT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BirthdayExtension {
    return new BirthdayExtension().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BirthdayExtension {
    return new BirthdayExtension().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BirthdayExtension {
    return new BirthdayExtension().fromJsonString(jsonString, options);
  }

  static equals(a: BirthdayExtension | PlainMessage<BirthdayExtension> | undefined, b: BirthdayExtension | PlainMessage<BirthdayExtension> | undefined): boolean {
    return proto3.util.equals(BirthdayExtension, a, b);
  }
}

/**
 * @generated from message BavariaCardTypeExtension
 */
export class BavariaCardTypeExtension extends Message<BavariaCardTypeExtension> {
  /**
   * @generated from field: optional BavariaCardType card_type = 1;
   */
  cardType?: BavariaCardType;

  constructor(data?: PartialMessage<BavariaCardTypeExtension>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "BavariaCardTypeExtension";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "card_type", kind: "enum", T: proto3.getEnumType(BavariaCardType), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BavariaCardTypeExtension {
    return new BavariaCardTypeExtension().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BavariaCardTypeExtension {
    return new BavariaCardTypeExtension().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BavariaCardTypeExtension {
    return new BavariaCardTypeExtension().fromJsonString(jsonString, options);
  }

  static equals(a: BavariaCardTypeExtension | PlainMessage<BavariaCardTypeExtension> | undefined, b: BavariaCardTypeExtension | PlainMessage<BavariaCardTypeExtension> | undefined): boolean {
    return proto3.util.equals(BavariaCardTypeExtension, a, b);
  }
}

/**
 * @generated from message StartDayExtension
 */
export class StartDayExtension extends Message<StartDayExtension> {
  /**
   * Start Day (calculated from 1970-01-01) of the beginning of the validity period.
   * Cards are always invalid on 1970-01-01 (because day = 0 is excluded).
   * The calculated day is inclusive (e.g. if day == 1, then the card is valid on 1970-01-02, but not on 1970-01-03).
   * Because the data type is unsigned, there are no dates before 1970-01-01.
   * 2^32days = 11 759 230.8 years.
   *
   * @generated from field: optional uint32 start_day = 1;
   */
  startDay?: number;

  constructor(data?: PartialMessage<StartDayExtension>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "StartDayExtension";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_day", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartDayExtension {
    return new StartDayExtension().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartDayExtension {
    return new StartDayExtension().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartDayExtension {
    return new StartDayExtension().fromJsonString(jsonString, options);
  }

  static equals(a: StartDayExtension | PlainMessage<StartDayExtension> | undefined, b: StartDayExtension | PlainMessage<StartDayExtension> | undefined): boolean {
    return proto3.util.equals(StartDayExtension, a, b);
  }
}

/**
 * @generated from message NuernbergPassIdExtension
 */
export class NuernbergPassIdExtension extends Message<NuernbergPassIdExtension> {
  /**
   * @generated from field: optional uint32 pass_id = 1;
   */
  passId?: number;

  /**
   * @generated from field: optional NuernergPassIdentifier identifier = 2;
   */
  identifier?: NuernergPassIdentifier;

  constructor(data?: PartialMessage<NuernbergPassIdExtension>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "NuernbergPassIdExtension";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pass_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
    { no: 2, name: "identifier", kind: "enum", T: proto3.getEnumType(NuernergPassIdentifier), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NuernbergPassIdExtension {
    return new NuernbergPassIdExtension().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NuernbergPassIdExtension {
    return new NuernbergPassIdExtension().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NuernbergPassIdExtension {
    return new NuernbergPassIdExtension().fromJsonString(jsonString, options);
  }

  static equals(a: NuernbergPassIdExtension | PlainMessage<NuernbergPassIdExtension> | undefined, b: NuernbergPassIdExtension | PlainMessage<NuernbergPassIdExtension> | undefined): boolean {
    return proto3.util.equals(NuernbergPassIdExtension, a, b);
  }
}

/**
 * @generated from message KoblenzReferenceNumberExtension
 */
export class KoblenzReferenceNumberExtension extends Message<KoblenzReferenceNumberExtension> {
  /**
   * @generated from field: optional string reference_number = 1;
   */
  referenceNumber?: string;

  constructor(data?: PartialMessage<KoblenzReferenceNumberExtension>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "KoblenzReferenceNumberExtension";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reference_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): KoblenzReferenceNumberExtension {
    return new KoblenzReferenceNumberExtension().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): KoblenzReferenceNumberExtension {
    return new KoblenzReferenceNumberExtension().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): KoblenzReferenceNumberExtension {
    return new KoblenzReferenceNumberExtension().fromJsonString(jsonString, options);
  }

  static equals(a: KoblenzReferenceNumberExtension | PlainMessage<KoblenzReferenceNumberExtension> | undefined, b: KoblenzReferenceNumberExtension | PlainMessage<KoblenzReferenceNumberExtension> | undefined): boolean {
    return proto3.util.equals(KoblenzReferenceNumberExtension, a, b);
  }
}

/**
 * @generated from message CardExtensions
 */
export class CardExtensions extends Message<CardExtensions> {
  /**
   * @generated from field: optional RegionExtension extension_region = 1;
   */
  extensionRegion?: RegionExtension;

  /**
   * @generated from field: optional BirthdayExtension extension_birthday = 2;
   */
  extensionBirthday?: BirthdayExtension;

  /**
   * @generated from field: optional NuernbergPassIdExtension extension_nuernberg_pass_id = 3;
   */
  extensionNuernbergPassId?: NuernbergPassIdExtension;

  /**
   * @generated from field: optional BavariaCardTypeExtension extension_bavaria_card_type = 4;
   */
  extensionBavariaCardType?: BavariaCardTypeExtension;

  /**
   * @generated from field: optional StartDayExtension extension_start_day = 5;
   */
  extensionStartDay?: StartDayExtension;

  /**
   * @generated from field: optional KoblenzReferenceNumberExtension extension_koblenz_reference_number = 6;
   */
  extensionKoblenzReferenceNumber?: KoblenzReferenceNumberExtension;

  constructor(data?: PartialMessage<CardExtensions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "CardExtensions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "extension_region", kind: "message", T: RegionExtension, opt: true },
    { no: 2, name: "extension_birthday", kind: "message", T: BirthdayExtension, opt: true },
    { no: 3, name: "extension_nuernberg_pass_id", kind: "message", T: NuernbergPassIdExtension, opt: true },
    { no: 4, name: "extension_bavaria_card_type", kind: "message", T: BavariaCardTypeExtension, opt: true },
    { no: 5, name: "extension_start_day", kind: "message", T: StartDayExtension, opt: true },
    { no: 6, name: "extension_koblenz_reference_number", kind: "message", T: KoblenzReferenceNumberExtension, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CardExtensions {
    return new CardExtensions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CardExtensions {
    return new CardExtensions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CardExtensions {
    return new CardExtensions().fromJsonString(jsonString, options);
  }

  static equals(a: CardExtensions | PlainMessage<CardExtensions> | undefined, b: CardExtensions | PlainMessage<CardExtensions> | undefined): boolean {
    return proto3.util.equals(CardExtensions, a, b);
  }
}

/**
 * For our hashing approach, we require that all fields (and subfields, recursively) of CardInfo are marked 'optional'.
 * Note that, 'optional' in proto3 "only" means explicit presence, i.e. it can be determined if a field marked
 * as optional is actually present in an instance of a proto. Asserting that all fields have explicit presence
 * enables us to add and remove fields from the proto in the future without making changes to our hashing algorithm
 * (and thus without having to keep track of different versions of the algorithm).
 * More notes on explicit presence: https://protobuf.dev/programming-guides/field_presence/#presence-in-proto3-apis
 *
 * @generated from message CardInfo
 */
export class CardInfo extends Message<CardInfo> {
  /**
   * The first and last name of the cardholder.
   *
   * @generated from field: optional string full_name = 1;
   */
  fullName?: string;

  /**
   * Expiration day (calculated from 1970-01-01).
   * Cards are always invalid on 1970-01-01 (because day = 0 is excluded).
   * The calculated day is inclusive (e.g. if day == 1, then the card is valid on 1970-01-02, but not on 1970-01-03).
   * Because the data type is unsigned, there are no dates before 1970-01-01.
   * 2^32days = 11 759 230.8 years.
   * Absent, if the card does not expire (in the case of a golden Bavarian EAK).
   *
   * @generated from field: optional uint32 expiration_day = 2;
   */
  expirationDay?: number;

  /**
   * Extensions
   *
   * @generated from field: optional CardExtensions extensions = 3;
   */
  extensions?: CardExtensions;

  constructor(data?: PartialMessage<CardInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "CardInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "expiration_day", kind: "scalar", T: 13 /* ScalarType.UINT32 */, opt: true },
    { no: 3, name: "extensions", kind: "message", T: CardExtensions, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CardInfo {
    return new CardInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CardInfo {
    return new CardInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CardInfo {
    return new CardInfo().fromJsonString(jsonString, options);
  }

  static equals(a: CardInfo | PlainMessage<CardInfo> | undefined, b: CardInfo | PlainMessage<CardInfo> | undefined): boolean {
    return proto3.util.equals(CardInfo, a, b);
  }
}

/**
 * @generated from message CardVerification
 */
export class CardVerification extends Message<CardVerification> {
  /**
   * @generated from field: optional bool cardValid = 1;
   */
  cardValid?: boolean;

  /**
   * Verification timestamp in seconds (calculated from 1970-01-01 UTC) as reported by the server.
   * Using uint64 should be good for 584,942,417,355 years after 1970.
   * This timestamp is used to invalidate the card after a certain time period not verified with the backend (in this case 7 days).
   * This timestamp will be updated after card activation and card validation on app start.
   *
   * @generated from field: optional uint64 verificationTimeStamp = 2;
   */
  verificationTimeStamp?: bigint;

  /**
   * If the verificationTimeStamp was out of sync with the local date time (at time of request) by at least 30 seconds.
   *
   * @generated from field: optional bool outOfSync = 3;
   */
  outOfSync?: boolean;

  /**
   * @generated from field: optional bool cardExtendable = 4;
   */
  cardExtendable?: boolean;

  constructor(data?: PartialMessage<CardVerification>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "CardVerification";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cardValid", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 2, name: "verificationTimeStamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 3, name: "outOfSync", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 4, name: "cardExtendable", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CardVerification {
    return new CardVerification().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CardVerification {
    return new CardVerification().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CardVerification {
    return new CardVerification().fromJsonString(jsonString, options);
  }

  static equals(a: CardVerification | PlainMessage<CardVerification> | undefined, b: CardVerification | PlainMessage<CardVerification> | undefined): boolean {
    return proto3.util.equals(CardVerification, a, b);
  }
}

/**
 * @generated from message QrCode
 */
export class QrCode extends Message<QrCode> {
  /**
   * @generated from oneof QrCode.qr_code
   */
  qrCode: {
    /**
     * @generated from field: DynamicActivationCode dynamic_activation_code = 1;
     */
    value: DynamicActivationCode;
    case: "dynamicActivationCode";
  } | {
    /**
     * @generated from field: DynamicVerificationCode dynamic_verification_code = 2;
     */
    value: DynamicVerificationCode;
    case: "dynamicVerificationCode";
  } | {
    /**
     * @generated from field: StaticVerificationCode static_verification_code = 3;
     */
    value: StaticVerificationCode;
    case: "staticVerificationCode";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<QrCode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "QrCode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dynamic_activation_code", kind: "message", T: DynamicActivationCode, oneof: "qr_code" },
    { no: 2, name: "dynamic_verification_code", kind: "message", T: DynamicVerificationCode, oneof: "qr_code" },
    { no: 3, name: "static_verification_code", kind: "message", T: StaticVerificationCode, oneof: "qr_code" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): QrCode {
    return new QrCode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): QrCode {
    return new QrCode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): QrCode {
    return new QrCode().fromJsonString(jsonString, options);
  }

  static equals(a: QrCode | PlainMessage<QrCode> | undefined, b: QrCode | PlainMessage<QrCode> | undefined): boolean {
    return proto3.util.equals(QrCode, a, b);
  }
}

/**
 * @generated from message DynamicActivationCode
 */
export class DynamicActivationCode extends Message<DynamicActivationCode> {
  /**
   * @generated from field: CardInfo info = 1;
   */
  info?: CardInfo;

  /**
   * @generated from field: bytes pepper = 2;
   */
  pepper = new Uint8Array(0);

  /**
   * @generated from field: bytes activation_secret = 3;
   */
  activationSecret = new Uint8Array(0);

  /**
   * @generated from field: bytes ec_signature = 4;
   */
  ecSignature = new Uint8Array(0);

  constructor(data?: PartialMessage<DynamicActivationCode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "DynamicActivationCode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "info", kind: "message", T: CardInfo },
    { no: 2, name: "pepper", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "activation_secret", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "ec_signature", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DynamicActivationCode {
    return new DynamicActivationCode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DynamicActivationCode {
    return new DynamicActivationCode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DynamicActivationCode {
    return new DynamicActivationCode().fromJsonString(jsonString, options);
  }

  static equals(a: DynamicActivationCode | PlainMessage<DynamicActivationCode> | undefined, b: DynamicActivationCode | PlainMessage<DynamicActivationCode> | undefined): boolean {
    return proto3.util.equals(DynamicActivationCode, a, b);
  }
}

/**
 * @generated from message DynamicUserCode
 */
export class DynamicUserCode extends Message<DynamicUserCode> {
  /**
   * @generated from field: CardInfo info = 1;
   */
  info?: CardInfo;

  /**
   * @generated from field: bytes pepper = 2;
   */
  pepper = new Uint8Array(0);

  /**
   * @generated from field: bytes totp_secret = 3;
   */
  totpSecret = new Uint8Array(0);

  /**
   * @generated from field: bytes ec_signature = 4;
   */
  ecSignature = new Uint8Array(0);

  /**
   * @generated from field: CardVerification card_verification = 5;
   */
  cardVerification?: CardVerification;

  constructor(data?: PartialMessage<DynamicUserCode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "DynamicUserCode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "info", kind: "message", T: CardInfo },
    { no: 2, name: "pepper", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "totp_secret", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "ec_signature", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 5, name: "card_verification", kind: "message", T: CardVerification },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DynamicUserCode {
    return new DynamicUserCode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DynamicUserCode {
    return new DynamicUserCode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DynamicUserCode {
    return new DynamicUserCode().fromJsonString(jsonString, options);
  }

  static equals(a: DynamicUserCode | PlainMessage<DynamicUserCode> | undefined, b: DynamicUserCode | PlainMessage<DynamicUserCode> | undefined): boolean {
    return proto3.util.equals(DynamicUserCode, a, b);
  }
}

/**
 * @generated from message DynamicVerificationCode
 */
export class DynamicVerificationCode extends Message<DynamicVerificationCode> {
  /**
   * @generated from field: CardInfo info = 1;
   */
  info?: CardInfo;

  /**
   * @generated from field: bytes pepper = 2;
   */
  pepper = new Uint8Array(0);

  /**
   * @generated from field: uint32 otp = 3;
   */
  otp = 0;

  constructor(data?: PartialMessage<DynamicVerificationCode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "DynamicVerificationCode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "info", kind: "message", T: CardInfo },
    { no: 2, name: "pepper", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "otp", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DynamicVerificationCode {
    return new DynamicVerificationCode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DynamicVerificationCode {
    return new DynamicVerificationCode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DynamicVerificationCode {
    return new DynamicVerificationCode().fromJsonString(jsonString, options);
  }

  static equals(a: DynamicVerificationCode | PlainMessage<DynamicVerificationCode> | undefined, b: DynamicVerificationCode | PlainMessage<DynamicVerificationCode> | undefined): boolean {
    return proto3.util.equals(DynamicVerificationCode, a, b);
  }
}

/**
 * @generated from message StaticVerificationCode
 */
export class StaticVerificationCode extends Message<StaticVerificationCode> {
  /**
   * @generated from field: CardInfo info = 1;
   */
  info?: CardInfo;

  /**
   * @generated from field: bytes pepper = 2;
   */
  pepper = new Uint8Array(0);

  /**
   * @generated from field: bytes ec_signature = 3;
   */
  ecSignature = new Uint8Array(0);

  constructor(data?: PartialMessage<StaticVerificationCode>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "StaticVerificationCode";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "info", kind: "message", T: CardInfo },
    { no: 2, name: "pepper", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "ec_signature", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StaticVerificationCode {
    return new StaticVerificationCode().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StaticVerificationCode {
    return new StaticVerificationCode().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StaticVerificationCode {
    return new StaticVerificationCode().fromJsonString(jsonString, options);
  }

  static equals(a: StaticVerificationCode | PlainMessage<StaticVerificationCode> | undefined, b: StaticVerificationCode | PlainMessage<StaticVerificationCode> | undefined): boolean {
    return proto3.util.equals(StaticVerificationCode, a, b);
  }
}


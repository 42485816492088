import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A 64-bit signed integer */
  Long: { input: any; output: any; }
  /** A file part in a multipart request */
  Upload: { input: any; output: any; }
};

export type AcceptingStore = {
  __typename?: 'AcceptingStore';
  category: Category;
  categoryId: Scalars['Int']['output'];
  contact: Contact;
  contactId: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  physicalStore?: Maybe<PhysicalStore>;
};

export enum ActivationState {
  DidNotOverwriteExisting = 'did_not_overwrite_existing',
  Failed = 'failed',
  Revoked = 'revoked',
  Success = 'success'
}

export type Address = {
  __typename?: 'Address';
  id: Scalars['Int']['output'];
  location?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  addressSupplement?: InputMaybe<ShortTextInput>;
  country: ShortTextInput;
  houseNumber: ShortTextInput;
  location: ShortTextInput;
  postalCode: ShortTextInput;
  street: ShortTextInput;
};

export type Administrator = {
  __typename?: 'Administrator';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['Int']['output']>;
  role: Role;
};

export type ApiTokenMetaData = {
  __typename?: 'ApiTokenMetaData';
  creatorEmail: Scalars['String']['output'];
  expirationDate: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  type: ApiTokenType;
};

export enum ApiTokenType {
  UserImport = 'USER_IMPORT',
  VerifiedApplication = 'VERIFIED_APPLICATION'
}

export type ApplicationDetailsInput = {
  applicationType?: InputMaybe<ApplicationType>;
  blueCardEntitlement?: InputMaybe<BlueCardEntitlementInput>;
  cardType: BavariaCardType;
  givenInformationIsCorrectAndComplete: Scalars['Boolean']['input'];
  goldenCardEntitlement?: InputMaybe<GoldenCardEntitlementInput>;
  hasAcceptedEmailUsage: Scalars['Boolean']['input'];
  hasAcceptedPrivacyPolicy: Scalars['Boolean']['input'];
  wantsDigitalCard: Scalars['Boolean']['input'];
  wantsPhysicalCard: Scalars['Boolean']['input'];
};

/**
 * An application for the Bayerische Ehrenamtskarte.
 * The field `cardType` specifies whether `blueCardEntitlement` or `goldenCardEntitlement` must be present/null.
 * The field `applicationType` must not be null if and only if `cardType` is BavariaCardType.BLUE.
 */
export type ApplicationInput = {
  applicationDetails: ApplicationDetailsInput;
  personalData: PersonalDataInput;
};

export enum ApplicationType {
  FirstApplication = 'FIRST_APPLICATION',
  RenewalApplication = 'RENEWAL_APPLICATION'
}

export type ApplicationVerificationView = {
  __typename?: 'ApplicationVerificationView';
  contactEmailAddress: Scalars['String']['output'];
  contactName: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  rejectedDate?: Maybe<Scalars['String']['output']>;
  verifiedDate?: Maybe<Scalars['String']['output']>;
};

export type ApplicationView = {
  __typename?: 'ApplicationView';
  cardCreated?: Maybe<Scalars['Boolean']['output']>;
  createdDate: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  jsonValue: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  regionId: Scalars['Int']['output'];
  verifications: Array<ApplicationVerificationView>;
  withdrawalDate?: Maybe<Scalars['String']['output']>;
};

export type AttachmentInput = {
  data: Scalars['Upload']['input'];
};

export type AuthDataInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export enum BavariaCardType {
  Blue = 'BLUE',
  Golden = 'GOLDEN'
}

/** Entitlement for a blue EAK. The field selected by entitlementType must not be null; all others must be null. */
export type BlueCardEntitlementInput = {
  entitlementType: BlueCardEntitlementType;
  juleicaEntitlement?: InputMaybe<BlueCardJuleicaEntitlementInput>;
  militaryReserveEntitlement?: InputMaybe<BlueCardMilitaryReserveEntitlementInput>;
  volunteerServiceEntitlement?: InputMaybe<BlueCardVolunteerServiceEntitlementInput>;
  workAtDepartmentEntitlement?: InputMaybe<BlueCardWorkAtDepartmentEntitlementInput>;
  workAtOrganizationsEntitlement?: InputMaybe<BlueCardWorkAtOrganizationsEntitlementInput>;
};

export enum BlueCardEntitlementType {
  Juleica = 'JULEICA',
  MilitaryReserve = 'MILITARY_RESERVE',
  VolunteerService = 'VOLUNTEER_SERVICE',
  WorkAtDepartment = 'WORK_AT_DEPARTMENT',
  WorkAtOrganizations = 'WORK_AT_ORGANIZATIONS'
}

export type BlueCardJuleicaEntitlementInput = {
  copyOfJuleicaBack?: InputMaybe<AttachmentInput>;
  copyOfJuleicaFront: AttachmentInput;
  juleicaExpirationDate: DateInput;
  juleicaNumber: ShortTextInput;
};

export type BlueCardMilitaryReserveEntitlementInput = {
  certificate: AttachmentInput;
};

export type BlueCardVolunteerServiceEntitlementInput = {
  certificate: AttachmentInput;
  programName: ShortTextInput;
};

export type BlueCardWorkAtDepartmentEntitlementInput = {
  certificate?: InputMaybe<AttachmentInput>;
  organization: OrganizationInput;
  responsibility: ShortTextInput;
};

export type BlueCardWorkAtOrganizationsEntitlementInput = {
  list: Array<WorkAtOrganizationInput>;
};

export type CsvAcceptingStoreInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  discountDE?: InputMaybe<Scalars['String']['input']>;
  discountEN?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  homepage?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  telephone?: InputMaybe<Scalars['String']['input']>;
};

export type CardActivationResultModel = {
  __typename?: 'CardActivationResultModel';
  activationState: ActivationState;
  activationTimeStamp: Scalars['String']['output'];
  totpSecret?: Maybe<Scalars['String']['output']>;
};

export type CardCreationResultModel = {
  __typename?: 'CardCreationResultModel';
  dynamicActivationCode: DynamicActivationCodeResult;
  staticVerificationCode?: Maybe<StaticVerificationCodeResult>;
};

export type CardStatisticsResultModel = {
  __typename?: 'CardStatisticsResultModel';
  cardsActivated: Scalars['Int']['output'];
  cardsCreated: Scalars['Int']['output'];
  region: Scalars['String']['output'];
};

export type CardVerificationModelInput = {
  cardInfoHashBase64: Scalars['String']['input'];
  codeType: CodeType;
  totp?: InputMaybe<Scalars['Int']['input']>;
};

export type CardVerificationResultModel = {
  __typename?: 'CardVerificationResultModel';
  extendable: Scalars['Boolean']['output'];
  valid: Scalars['Boolean']['output'];
  verificationTimeStamp: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum CodeType {
  Dynamic = 'DYNAMIC',
  Static = 'STATIC'
}

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  telephone?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type Coordinates = {
  __typename?: 'Coordinates';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type CoordinatesInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

/** A local date in the format yyyy-MM-dd */
export type DateInput = {
  date: Scalars['String']['input'];
};

export type DynamicActivationCodeResult = {
  __typename?: 'DynamicActivationCodeResult';
  cardInfoHashBase64: Scalars['String']['output'];
  codeBase64: Scalars['String']['output'];
};

/** An email address with at most 300 characters */
export type EmailInput = {
  email: Scalars['String']['input'];
};

/** Entitlement for a golden EAK. The field selected by entitlementType must not be null; all others must be null. */
export type GoldenCardEntitlementInput = {
  entitlementType: GoldenCardEntitlementType;
  honoredByMinisterPresidentEntitlement?: InputMaybe<GoldenCardHonoredByMinisterPresidentEntitlementInput>;
  militaryReserveEntitlement?: InputMaybe<GoldenCardMilitaryReserveEntitlementInput>;
  workAtDepartmentEntitlement?: InputMaybe<GoldenCardWorkAtDepartmentEntitlementInput>;
  workAtOrganizationsEntitlement?: InputMaybe<GoldenCardWorkAtOrganizationsEntitlementInput>;
};

export enum GoldenCardEntitlementType {
  HonoredByMinisterPresident = 'HONORED_BY_MINISTER_PRESIDENT',
  MilitaryReserve = 'MILITARY_RESERVE',
  WorkAtDepartment = 'WORK_AT_DEPARTMENT',
  WorkAtOrganizations = 'WORK_AT_ORGANIZATIONS'
}

export type GoldenCardHonoredByMinisterPresidentEntitlementInput = {
  certificate: AttachmentInput;
};

export type GoldenCardMilitaryReserveEntitlementInput = {
  certificate: AttachmentInput;
};

export type GoldenCardWorkAtDepartmentEntitlementInput = {
  certificate?: InputMaybe<AttachmentInput>;
  organization: OrganizationInput;
  responsibility: ShortTextInput;
};

export type GoldenCardWorkAtOrganizationsEntitlementInput = {
  list: Array<WorkAtOrganizationInput>;
};

export enum GraphQlExceptionCode {
  EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
  InvalidCardHash = 'INVALID_CARD_HASH',
  InvalidCodeType = 'INVALID_CODE_TYPE',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  InvalidDataPolicySize = 'INVALID_DATA_POLICY_SIZE',
  InvalidFileSize = 'INVALID_FILE_SIZE',
  InvalidFileType = 'INVALID_FILE_TYPE',
  InvalidInput = 'INVALID_INPUT',
  InvalidJson = 'INVALID_JSON',
  InvalidLink = 'INVALID_LINK',
  InvalidNoteSize = 'INVALID_NOTE_SIZE',
  InvalidPassword = 'INVALID_PASSWORD',
  InvalidPasswordResetLink = 'INVALID_PASSWORD_RESET_LINK',
  InvalidQrCodeSize = 'INVALID_QR_CODE_SIZE',
  InvalidRole = 'INVALID_ROLE',
  MailNotSent = 'MAIL_NOT_SENT',
  PasswordResetKeyExpired = 'PASSWORD_RESET_KEY_EXPIRED',
  RegionNotActivatedCardConfirmationMail = 'REGION_NOT_ACTIVATED_CARD_CONFIRMATION_MAIL',
  RegionNotActivatedForApplication = 'REGION_NOT_ACTIVATED_FOR_APPLICATION',
  RegionNotFound = 'REGION_NOT_FOUND',
  UserEntitlementExpired = 'USER_ENTITLEMENT_EXPIRED',
  UserEntitlementNotFound = 'USER_ENTITLEMENT_NOT_FOUND'
}

export type IdsParamsInput = {
  ids: Array<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Activate a dynamic entitlement card */
  activateCard: CardActivationResultModel;
  /** Stores a new application for an EAK */
  addEakApplication: Scalars['Boolean']['output'];
  /** Changes an administrator's password */
  changePassword: Scalars['Boolean']['output'];
  /** Creates a new administrator */
  createAdministrator: Scalars['Boolean']['output'];
  /** Creates a new api token for user import endpoint */
  createApiToken: Scalars['String']['output'];
  /** Creates a new digital entitlementcard from self-service portal */
  createCardFromSelfService: CardCreationResultModel;
  /** Creates a new digital entitlementcard and returns it */
  createCardsByCardInfos: Array<CardCreationResultModel>;
  /** Deletes an existing administrator */
  deleteAdministrator: Scalars['Boolean']['output'];
  /** Deletes a selected API token */
  deleteApiToken: Scalars['Int']['output'];
  /** Deletes the application with specified id */
  deleteApplication: Scalars['Boolean']['output'];
  /** Deletes a batch of cards (that have not yet been activated) */
  deleteInactiveCards: Scalars['Boolean']['output'];
  /** Edits an existing administrator */
  editAdministrator: Scalars['Boolean']['output'];
  /** Import accepting stores via csv */
  importAcceptingStores: StoreImportReturnResultModel;
  /** Reset the administrator's password */
  resetPassword: Scalars['Boolean']['output'];
  /** Sends a confirmation mail to the user when the card creation was successful */
  sendCardCreationConfirmationMail: Scalars['Boolean']['output'];
  /** Sends a mail that allows the administrator to reset their password. */
  sendResetMail: Scalars['Boolean']['output'];
  /** Signs in an administrator */
  signIn: SignInPayload;
  /** Updates a note of an application */
  updateApplicationNote: Scalars['Boolean']['output'];
  /** Updates the data privacy policy of a region */
  updateDataPrivacy: Scalars['Boolean']['output'];
  /** Updates the notification settings */
  updateNotificationSettings: Scalars['Boolean']['output'];
  /** Updates the region specific settings */
  updateRegionSettings: Scalars['Boolean']['output'];
  /** Verifies or rejects an application verification */
  verifyOrRejectApplicationVerification: Scalars['Boolean']['output'];
  /** Withdraws the application */
  withdrawApplication: Scalars['Boolean']['output'];
};


export type MutationActivateCardArgs = {
  activationSecretBase64: Scalars['String']['input'];
  cardInfoHashBase64: Scalars['String']['input'];
  overwrite: Scalars['Boolean']['input'];
  project: Scalars['String']['input'];
};


export type MutationAddEakApplicationArgs = {
  application: ApplicationInput;
  project: Scalars['String']['input'];
  regionId: Scalars['Int']['input'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  project: Scalars['String']['input'];
};


export type MutationCreateAdministratorArgs = {
  email: Scalars['String']['input'];
  project: Scalars['String']['input'];
  regionId?: InputMaybe<Scalars['Int']['input']>;
  role: Role;
  sendWelcomeMail: Scalars['Boolean']['input'];
};


export type MutationCreateApiTokenArgs = {
  expiresIn: Scalars['Int']['input'];
};


export type MutationCreateCardFromSelfServiceArgs = {
  encodedCardInfo: Scalars['String']['input'];
  generateStaticCode: Scalars['Boolean']['input'];
  project: Scalars['String']['input'];
};


export type MutationCreateCardsByCardInfosArgs = {
  applicationIdToMarkAsProcessed?: InputMaybe<Scalars['Int']['input']>;
  encodedCardInfos: Array<Scalars['String']['input']>;
  generateStaticCodes: Scalars['Boolean']['input'];
  project: Scalars['String']['input'];
};


export type MutationDeleteAdministratorArgs = {
  adminId: Scalars['Int']['input'];
  project: Scalars['String']['input'];
};


export type MutationDeleteApiTokenArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteApplicationArgs = {
  applicationId: Scalars['Int']['input'];
};


export type MutationDeleteInactiveCardsArgs = {
  cardInfoHashBase64List: Array<Scalars['String']['input']>;
  regionId: Scalars['Int']['input'];
};


export type MutationEditAdministratorArgs = {
  adminId: Scalars['Int']['input'];
  newEmail: Scalars['String']['input'];
  newRegionId?: InputMaybe<Scalars['Int']['input']>;
  newRole: Role;
  project: Scalars['String']['input'];
};


export type MutationImportAcceptingStoresArgs = {
  dryRun: Scalars['Boolean']['input'];
  project: Scalars['String']['input'];
  stores: Array<CsvAcceptingStoreInput>;
};


export type MutationResetPasswordArgs = {
  email: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  passwordResetKey: Scalars['String']['input'];
  project: Scalars['String']['input'];
};


export type MutationSendCardCreationConfirmationMailArgs = {
  deepLink: Scalars['String']['input'];
  project: Scalars['String']['input'];
  recipientAddress: Scalars['String']['input'];
  recipientName: Scalars['String']['input'];
  regionId: Scalars['Int']['input'];
};


export type MutationSendResetMailArgs = {
  email: Scalars['String']['input'];
  project: Scalars['String']['input'];
};


export type MutationSignInArgs = {
  authData: AuthDataInput;
  project: Scalars['String']['input'];
};


export type MutationUpdateApplicationNoteArgs = {
  applicationId: Scalars['Int']['input'];
  noteText: Scalars['String']['input'];
};


export type MutationUpdateDataPrivacyArgs = {
  dataPrivacyText: Scalars['String']['input'];
  regionId: Scalars['Int']['input'];
};


export type MutationUpdateNotificationSettingsArgs = {
  notificationSettings: NotificationSettingsInput;
  project: Scalars['String']['input'];
};


export type MutationUpdateRegionSettingsArgs = {
  activatedForApplication: Scalars['Boolean']['input'];
  activatedForConfirmationMail: Scalars['Boolean']['input'];
  regionId: Scalars['Int']['input'];
};


export type MutationVerifyOrRejectApplicationVerificationArgs = {
  accessKey: Scalars['String']['input'];
  project: Scalars['String']['input'];
  verified: Scalars['Boolean']['input'];
};


export type MutationWithdrawApplicationArgs = {
  accessKey: Scalars['String']['input'];
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  notificationOnApplication: Scalars['Boolean']['output'];
  notificationOnVerification: Scalars['Boolean']['output'];
};

export type NotificationSettingsInput = {
  notificationOnApplication: Scalars['Boolean']['input'];
  notificationOnVerification: Scalars['Boolean']['input'];
};

export type OrganizationContactInput = {
  email: EmailInput;
  hasGivenPermission: Scalars['Boolean']['input'];
  name: ShortTextInput;
  telephone: ShortTextInput;
};

export type OrganizationInput = {
  address: AddressInput;
  category: ShortTextInput;
  contact: OrganizationContactInput;
  name: ShortTextInput;
};

export type PersonalDataInput = {
  address: AddressInput;
  dateOfBirth: DateInput;
  emailAddress: EmailInput;
  forenames: ShortTextInput;
  surname: ShortTextInput;
  telephone?: InputMaybe<ShortTextInput>;
};

export type PhysicalStore = {
  __typename?: 'PhysicalStore';
  address: Address;
  addressId: Scalars['Int']['output'];
  coordinates: Coordinates;
  id: Scalars['Int']['output'];
  store: AcceptingStore;
  storeId: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** Return list of all categories. */
  categories: Array<Category>;
  /** Verify password reset link */
  checkPasswordResetLink: Scalars['Boolean']['output'];
  /** Gets metadata of all api tokens for a project */
  getApiTokenMetaData: Array<ApiTokenMetaData>;
  /** Queries an application by application accessKey */
  getApplicationByApplicant: ApplicationView;
  /** Queries an application by application verification accessKey */
  getApplicationByApplicationVerificationAccessKey: ApplicationView;
  /** Queries an application verification by application verification accessKey */
  getApplicationVerification: ApplicationVerificationView;
  /** Queries all applications for a specific region */
  getApplications: Array<ApplicationView>;
  /** Returns card statistics for project. Start and end dates are inclusive. */
  getCardStatisticsInProject: Array<CardStatisticsResultModel>;
  /** Returns card statistics for region. Start and end dates are inclusive. */
  getCardStatisticsInRegion: Array<CardStatisticsResultModel>;
  /** Get the pepper for Koblenz user hashing */
  getHashingPepper: Scalars['String']['output'];
  /** Get the notification settings of the authenticated administrator */
  getNotificationSettings: NotificationSettings;
  /** Returns all administrators in a project. This query requires the role PROJECT_ADMIN. */
  getUsersInProject: Array<Administrator>;
  /** Returns all administrators in a region. This query requires the role REGION_ADMIN or PROJECT_ADMIN. */
  getUsersInRegion: Array<Administrator>;
  /**
   * Return list of all accepting stores in the eak bayern project.
   * @deprecated Deprecated in favor of project specific query, replace with physicalStoresInProject
   */
  physicalStores: Array<PhysicalStore>;
  /**
   * Returns list of all accepting stores queried by ids in the eak bayern project.
   * @deprecated Deprecated in favor of project specific query, replace with physicalStoresByIdInProject
   */
  physicalStoresById: Array<Maybe<PhysicalStore>>;
  /** Returns list of all accepting stores in the given project queried by ids. */
  physicalStoresByIdInProject: Array<Maybe<PhysicalStore>>;
  /** Returns list of all accepting stores in the given project. */
  physicalStoresInProject: Array<PhysicalStore>;
  /** Returns region data for specific region. */
  regionByRegionId: Region;
  /**
   * Return list of all regions in the eak bayern project.
   * @deprecated Deprecated in favor of project specific query, replace with regionsInProject
   */
  regions: Array<Region>;
  /**
   * Returns regions queried by ids in the eak bayern project.
   * @deprecated Deprecated in favor of project specific query, replace with regionsByIdInProject
   */
  regionsById: Array<Maybe<Region>>;
  /** Returns regions queried by ids in the given project. */
  regionsByIdInProject: Array<Maybe<Region>>;
  /** Returns regions by postal code. Works only for the EAK project in which each region has an appropriate regionIdentifier. */
  regionsByPostalCode: Array<Region>;
  /** Return list of all regions in the given project. */
  regionsInProject: Array<Region>;
  /**
   * Search for accepting stores using searchText and categoryIds in the eak bayern project.
   * @deprecated Deprecated in favor of project specific query, replace with searchAcceptingStoresInProject
   */
  searchAcceptingStores: Array<AcceptingStore>;
  /** Search for accepting stores in the given project using searchText and categoryIds. */
  searchAcceptingStoresInProject: Array<AcceptingStore>;
  /**
   * Returns whether there is a card in the given project with that hash registered for that this TOTP is currently valid and a timestamp of the last check
   * @deprecated Deprecated since May 2023 in favor of CardVerificationResultModel that return a current timestamp, replace with verifyCardInProjectV2
   */
  verifyCardInProject: Scalars['Boolean']['output'];
  /** Returns whether there is a card in the given project with that hash registered for that this TOTP is currently valid, extendable and a timestamp of the last check */
  verifyCardInProjectV2: CardVerificationResultModel;
  /** Returns the requesting administrator as retrieved from his JWT token. */
  whoAmI: Administrator;
};


export type QueryCheckPasswordResetLinkArgs = {
  project: Scalars['String']['input'];
  resetKey: Scalars['String']['input'];
};


export type QueryGetApplicationByApplicantArgs = {
  accessKey: Scalars['String']['input'];
};


export type QueryGetApplicationByApplicationVerificationAccessKeyArgs = {
  applicationVerificationAccessKey: Scalars['String']['input'];
};


export type QueryGetApplicationVerificationArgs = {
  applicationVerificationAccessKey: Scalars['String']['input'];
};


export type QueryGetApplicationsArgs = {
  regionId: Scalars['Int']['input'];
};


export type QueryGetCardStatisticsInProjectArgs = {
  dateEnd: Scalars['String']['input'];
  dateStart: Scalars['String']['input'];
  project: Scalars['String']['input'];
};


export type QueryGetCardStatisticsInRegionArgs = {
  dateEnd: Scalars['String']['input'];
  dateStart: Scalars['String']['input'];
  project: Scalars['String']['input'];
  regionId: Scalars['Int']['input'];
};


export type QueryGetNotificationSettingsArgs = {
  project: Scalars['String']['input'];
};


export type QueryGetUsersInProjectArgs = {
  project: Scalars['String']['input'];
};


export type QueryGetUsersInRegionArgs = {
  regionId: Scalars['Int']['input'];
};


export type QueryPhysicalStoresByIdArgs = {
  params: IdsParamsInput;
};


export type QueryPhysicalStoresByIdInProjectArgs = {
  ids: Array<Scalars['Int']['input']>;
  project: Scalars['String']['input'];
};


export type QueryPhysicalStoresInProjectArgs = {
  project: Scalars['String']['input'];
};


export type QueryRegionByRegionIdArgs = {
  regionId: Scalars['Int']['input'];
};


export type QueryRegionsByIdArgs = {
  params: IdsParamsInput;
};


export type QueryRegionsByIdInProjectArgs = {
  ids: Array<Scalars['Int']['input']>;
  project: Scalars['String']['input'];
};


export type QueryRegionsByPostalCodeArgs = {
  postalCode: Scalars['String']['input'];
  project: Scalars['String']['input'];
};


export type QueryRegionsInProjectArgs = {
  project: Scalars['String']['input'];
};


export type QuerySearchAcceptingStoresArgs = {
  params: SearchParamsInput;
};


export type QuerySearchAcceptingStoresInProjectArgs = {
  params: SearchParamsInput;
  project: Scalars['String']['input'];
};


export type QueryVerifyCardInProjectArgs = {
  card: CardVerificationModelInput;
  project: Scalars['String']['input'];
};


export type QueryVerifyCardInProjectV2Args = {
  card: CardVerificationModelInput;
  project: Scalars['String']['input'];
};


export type QueryWhoAmIArgs = {
  project: Scalars['String']['input'];
};

export type Region = {
  __typename?: 'Region';
  activatedForApplication: Scalars['Boolean']['output'];
  activatedForCardConfirmationMail: Scalars['Boolean']['output'];
  dataPrivacyPolicy?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  prefix: Scalars['String']['output'];
  regionIdentifier?: Maybe<Scalars['String']['output']>;
};

export enum Role {
  ExternalVerifiedApiUser = 'EXTERNAL_VERIFIED_API_USER',
  NoRights = 'NO_RIGHTS',
  ProjectAdmin = 'PROJECT_ADMIN',
  ProjectStoreManager = 'PROJECT_STORE_MANAGER',
  RegionAdmin = 'REGION_ADMIN',
  RegionManager = 'REGION_MANAGER'
}

export type SearchParamsInput = {
  categoryIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  coordinates?: InputMaybe<CoordinatesInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Long']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

/** A String wrapper that expects a non-empty string with at most 300 characters */
export type ShortTextInput = {
  shortText: Scalars['String']['input'];
};

export type SignInPayload = {
  __typename?: 'SignInPayload';
  token: Scalars['String']['output'];
};

export type StaticVerificationCodeResult = {
  __typename?: 'StaticVerificationCodeResult';
  cardInfoHashBase64: Scalars['String']['output'];
  codeBase64: Scalars['String']['output'];
};

export type StoreImportReturnResultModel = {
  __typename?: 'StoreImportReturnResultModel';
  storesCreated: Scalars['Int']['output'];
  storesDeleted: Scalars['Int']['output'];
  storesUntouched: Scalars['Int']['output'];
};

export type WorkAtOrganizationInput = {
  amountOfWork: Scalars['Float']['input'];
  certificate?: InputMaybe<AttachmentInput>;
  isAlreadyVerified?: InputMaybe<Scalars['Boolean']['input']>;
  organization: OrganizationInput;
  payment: Scalars['Boolean']['input'];
  responsibility: ShortTextInput;
  workSinceDate: DateInput;
};

export type AddEakApplicationMutationVariables = Exact<{
  regionId: Scalars['Int']['input'];
  application: ApplicationInput;
  project: Scalars['String']['input'];
}>;


export type AddEakApplicationMutation = { __typename?: 'Mutation', result: boolean };

export type DeleteApplicationMutationVariables = Exact<{
  applicationId: Scalars['Int']['input'];
}>;


export type DeleteApplicationMutation = { __typename?: 'Mutation', deleted: boolean };

export type GetApplicationByApplicationVerificationAccessKeyQueryVariables = Exact<{
  applicationVerificationAccessKey: Scalars['String']['input'];
}>;


export type GetApplicationByApplicationVerificationAccessKeyQuery = { __typename?: 'Query', application: { __typename?: 'ApplicationView', id: number, createdDate: string, jsonValue: string, withdrawalDate?: string | null }, verification: { __typename?: 'ApplicationVerificationView', contactName: string, organizationName: string, verifiedDate?: string | null, rejectedDate?: string | null } };

export type GetApplicationByApplicantQueryVariables = Exact<{
  accessKey: Scalars['String']['input'];
}>;


export type GetApplicationByApplicantQuery = { __typename?: 'Query', application: { __typename?: 'ApplicationView', id: number, createdDate: string, jsonValue: string, withdrawalDate?: string | null, verifications: Array<{ __typename?: 'ApplicationVerificationView', contactEmailAddress: string, organizationName: string, verifiedDate?: string | null, rejectedDate?: string | null }> } };

export type GetApplicationsQueryVariables = Exact<{
  regionId: Scalars['Int']['input'];
}>;


export type GetApplicationsQuery = { __typename?: 'Query', applications: Array<{ __typename?: 'ApplicationView', id: number, createdDate: string, jsonValue: string, withdrawalDate?: string | null, note?: string | null, cardCreated?: boolean | null, verifications: Array<{ __typename?: 'ApplicationVerificationView', contactEmailAddress: string, organizationName: string, verifiedDate?: string | null, rejectedDate?: string | null }> }> };

export type UpdateApplicationNoteMutationVariables = Exact<{
  applicationId: Scalars['Int']['input'];
  text: Scalars['String']['input'];
}>;


export type UpdateApplicationNoteMutation = { __typename?: 'Mutation', success: boolean };

export type VerifyOrRejectApplicationVerificationMutationVariables = Exact<{
  project: Scalars['String']['input'];
  accessKey: Scalars['String']['input'];
  verified: Scalars['Boolean']['input'];
}>;


export type VerifyOrRejectApplicationVerificationMutation = { __typename?: 'Mutation', result: boolean };

export type WithdrawApplicationMutationVariables = Exact<{
  accessKey: Scalars['String']['input'];
}>;


export type WithdrawApplicationMutation = { __typename?: 'Mutation', isWithdrawed: boolean };

export type ChangePasswordMutationVariables = Exact<{
  project: Scalars['String']['input'];
  email: Scalars['String']['input'];
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', result: boolean };

export type CheckPasswordResetLinkQueryVariables = Exact<{
  project: Scalars['String']['input'];
  resetKey: Scalars['String']['input'];
}>;


export type CheckPasswordResetLinkQuery = { __typename?: 'Query', valid: boolean };

export type CreateApiTokenMutationVariables = Exact<{
  expiresIn: Scalars['Int']['input'];
}>;


export type CreateApiTokenMutation = { __typename?: 'Mutation', createApiTokenPayload: string };

export type DeleteApiTokenMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type DeleteApiTokenMutation = { __typename?: 'Mutation', deleteApiToken: number };

export type GetApiTokenMetaDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetApiTokenMetaDataQuery = { __typename?: 'Query', tokenMetaData: Array<{ __typename?: 'ApiTokenMetaData', id: number, creatorEmail: string, expirationDate: string, type: ApiTokenType }> };

export type GetHashingPepperQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHashingPepperQuery = { __typename?: 'Query', pepper: string };

export type ResetPasswordMutationVariables = Exact<{
  project: Scalars['String']['input'];
  email: Scalars['String']['input'];
  passwordResetKey: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', result: boolean };

export type SendResetMailMutationVariables = Exact<{
  project: Scalars['String']['input'];
  email: Scalars['String']['input'];
}>;


export type SendResetMailMutation = { __typename?: 'Mutation', result: boolean };

export type SignInMutationVariables = Exact<{
  project: Scalars['String']['input'];
  authData: AuthDataInput;
}>;


export type SignInMutation = { __typename?: 'Mutation', signInPayload: { __typename?: 'SignInPayload', token: string } };

export type WhoAmIQueryVariables = Exact<{
  project: Scalars['String']['input'];
}>;


export type WhoAmIQuery = { __typename?: 'Query', me: { __typename?: 'Administrator', id: number, email: string, role: Role, region?: { __typename?: 'Region', id: number, name: string, prefix: string, activatedForApplication: boolean, activatedForCardConfirmationMail: boolean } | null } };

export type CreateCardsMutationVariables = Exact<{
  project: Scalars['String']['input'];
  encodedCardInfos: Array<Scalars['String']['input']> | Scalars['String']['input'];
  generateStaticCodes: Scalars['Boolean']['input'];
  applicationIdToMarkAsProcessed?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CreateCardsMutation = { __typename?: 'Mutation', cards: Array<{ __typename?: 'CardCreationResultModel', dynamicActivationCode: { __typename?: 'DynamicActivationCodeResult', cardInfoHashBase64: string, codeBase64: string }, staticVerificationCode?: { __typename?: 'StaticVerificationCodeResult', cardInfoHashBase64: string, codeBase64: string } | null }> };

export type CreateCardsFromSelfServiceMutationVariables = Exact<{
  project: Scalars['String']['input'];
  encodedCardInfo: Scalars['String']['input'];
  generateStaticCodes: Scalars['Boolean']['input'];
}>;


export type CreateCardsFromSelfServiceMutation = { __typename?: 'Mutation', card: { __typename?: 'CardCreationResultModel', dynamicActivationCode: { __typename?: 'DynamicActivationCodeResult', cardInfoHashBase64: string, codeBase64: string }, staticVerificationCode?: { __typename?: 'StaticVerificationCodeResult', cardInfoHashBase64: string, codeBase64: string } | null } };

export type DeleteCardsMutationVariables = Exact<{
  regionId: Scalars['Int']['input'];
  cardInfoHashBase64List: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type DeleteCardsMutation = { __typename?: 'Mutation', deleteInactiveCards: boolean };

export type GetCardStatisticsInProjectQueryVariables = Exact<{
  projectId: Scalars['String']['input'];
  dateStart: Scalars['String']['input'];
  dateEnd: Scalars['String']['input'];
}>;


export type GetCardStatisticsInProjectQuery = { __typename?: 'Query', result: Array<{ __typename?: 'CardStatisticsResultModel', region: string, cardsCreated: number, cardsActivated: number }> };

export type GetCardStatisticsInRegionQueryVariables = Exact<{
  projectId: Scalars['String']['input'];
  regionId: Scalars['Int']['input'];
  dateStart: Scalars['String']['input'];
  dateEnd: Scalars['String']['input'];
}>;


export type GetCardStatisticsInRegionQuery = { __typename?: 'Query', result: Array<{ __typename?: 'CardStatisticsResultModel', region: string, cardsCreated: number, cardsActivated: number }> };

export type SendCardCreationConfirmationMailMutationVariables = Exact<{
  project: Scalars['String']['input'];
  regionId: Scalars['Int']['input'];
  recipientAddress: Scalars['String']['input'];
  recipientName: Scalars['String']['input'];
  deepLink: Scalars['String']['input'];
}>;


export type SendCardCreationConfirmationMailMutation = { __typename?: 'Mutation', result: boolean };

export type GetDataPolicyQueryVariables = Exact<{
  regionId: Scalars['Int']['input'];
}>;


export type GetDataPolicyQuery = { __typename?: 'Query', dataPolicy: { __typename?: 'Region', dataPrivacyPolicy?: string | null } };

export type GetRegionSettingsByIdQueryVariables = Exact<{
  regionId: Scalars['Int']['input'];
}>;


export type GetRegionSettingsByIdQuery = { __typename?: 'Query', result: { __typename?: 'Region', activatedForApplication: boolean, activatedForCardConfirmationMail: boolean } };

export type GetRegionsQueryVariables = Exact<{
  project: Scalars['String']['input'];
}>;


export type GetRegionsQuery = { __typename?: 'Query', regions: Array<{ __typename?: 'Region', id: number, prefix: string, name: string, regionIdentifier?: string | null, activatedForApplication: boolean, activatedForCardConfirmationMail: boolean }> };

export type GetRegionsByPostalCodeQueryVariables = Exact<{
  postalCode: Scalars['String']['input'];
  project: Scalars['String']['input'];
}>;


export type GetRegionsByPostalCodeQuery = { __typename?: 'Query', regions: Array<{ __typename?: 'Region', id: number, name: string, prefix: string, activatedForApplication: boolean, activatedForCardConfirmationMail: boolean }> };

export type UpdateDataPolicyMutationVariables = Exact<{
  regionId: Scalars['Int']['input'];
  text: Scalars['String']['input'];
}>;


export type UpdateDataPolicyMutation = { __typename?: 'Mutation', dataPolicy: boolean };

export type UpdateRegionSettingsMutationVariables = Exact<{
  regionId: Scalars['Int']['input'];
  activatedForApplication: Scalars['Boolean']['input'];
  activatedForConfirmationMail: Scalars['Boolean']['input'];
}>;


export type UpdateRegionSettingsMutation = { __typename?: 'Mutation', result: boolean };

export type ImportAcceptingStoresMutationVariables = Exact<{
  stores: Array<CsvAcceptingStoreInput> | CsvAcceptingStoreInput;
  project: Scalars['String']['input'];
  dryRun: Scalars['Boolean']['input'];
}>;


export type ImportAcceptingStoresMutation = { __typename?: 'Mutation', result: { __typename?: 'StoreImportReturnResultModel', storesCreated: number, storesDeleted: number, storesUntouched: number } };

export type CreateAdministratorMutationVariables = Exact<{
  project: Scalars['String']['input'];
  email: Scalars['String']['input'];
  regionId?: InputMaybe<Scalars['Int']['input']>;
  role: Role;
  sendWelcomeMail: Scalars['Boolean']['input'];
}>;


export type CreateAdministratorMutation = { __typename?: 'Mutation', result: boolean };

export type DeleteAdministratorMutationVariables = Exact<{
  project: Scalars['String']['input'];
  adminId: Scalars['Int']['input'];
}>;


export type DeleteAdministratorMutation = { __typename?: 'Mutation', result: boolean };

export type EditAdministratorMutationVariables = Exact<{
  project: Scalars['String']['input'];
  adminId: Scalars['Int']['input'];
  newEmail: Scalars['String']['input'];
  newRegionId?: InputMaybe<Scalars['Int']['input']>;
  newRole: Role;
}>;


export type EditAdministratorMutation = { __typename?: 'Mutation', result: boolean };

export type GetNotificationSettingsQueryVariables = Exact<{
  project: Scalars['String']['input'];
}>;


export type GetNotificationSettingsQuery = { __typename?: 'Query', notificationSettings: { __typename?: 'NotificationSettings', notificationOnApplication: boolean, notificationOnVerification: boolean } };

export type GetUsersInProjectQueryVariables = Exact<{
  project: Scalars['String']['input'];
}>;


export type GetUsersInProjectQuery = { __typename?: 'Query', users: Array<{ __typename?: 'Administrator', regionId?: number | null, email: string, id: number, role: Role }> };

export type GetUsersInRegionQueryVariables = Exact<{
  regionId: Scalars['Int']['input'];
}>;


export type GetUsersInRegionQuery = { __typename?: 'Query', users: Array<{ __typename?: 'Administrator', regionId?: number | null, email: string, id: number, role: Role }> };

export type UpdateNotificationSettingsMutationVariables = Exact<{
  project: Scalars['String']['input'];
  notificationSettings: NotificationSettingsInput;
}>;


export type UpdateNotificationSettingsMutation = { __typename?: 'Mutation', updateNotificationSettings: boolean };


export const AddEakApplicationDocument = gql`
    mutation addEakApplication($regionId: Int!, $application: ApplicationInput!, $project: String!) {
  result: addEakApplication(
    regionId: $regionId
    application: $application
    project: $project
  )
}
    `;
export type AddEakApplicationMutationFn = Apollo.MutationFunction<AddEakApplicationMutation, AddEakApplicationMutationVariables>;

/**
 * __useAddEakApplicationMutation__
 *
 * To run a mutation, you first call `useAddEakApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEakApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEakApplicationMutation, { data, loading, error }] = useAddEakApplicationMutation({
 *   variables: {
 *      regionId: // value for 'regionId'
 *      application: // value for 'application'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useAddEakApplicationMutation(baseOptions?: Apollo.MutationHookOptions<AddEakApplicationMutation, AddEakApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEakApplicationMutation, AddEakApplicationMutationVariables>(AddEakApplicationDocument, options);
      }
export type AddEakApplicationMutationHookResult = ReturnType<typeof useAddEakApplicationMutation>;
export type AddEakApplicationMutationResult = Apollo.MutationResult<AddEakApplicationMutation>;
export type AddEakApplicationMutationOptions = Apollo.BaseMutationOptions<AddEakApplicationMutation, AddEakApplicationMutationVariables>;
export const DeleteApplicationDocument = gql`
    mutation deleteApplication($applicationId: Int!) {
  deleted: deleteApplication(applicationId: $applicationId)
}
    `;
export type DeleteApplicationMutationFn = Apollo.MutationFunction<DeleteApplicationMutation, DeleteApplicationMutationVariables>;

/**
 * __useDeleteApplicationMutation__
 *
 * To run a mutation, you first call `useDeleteApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApplicationMutation, { data, loading, error }] = useDeleteApplicationMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useDeleteApplicationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApplicationMutation, DeleteApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApplicationMutation, DeleteApplicationMutationVariables>(DeleteApplicationDocument, options);
      }
export type DeleteApplicationMutationHookResult = ReturnType<typeof useDeleteApplicationMutation>;
export type DeleteApplicationMutationResult = Apollo.MutationResult<DeleteApplicationMutation>;
export type DeleteApplicationMutationOptions = Apollo.BaseMutationOptions<DeleteApplicationMutation, DeleteApplicationMutationVariables>;
export const GetApplicationByApplicationVerificationAccessKeyDocument = gql`
    query getApplicationByApplicationVerificationAccessKey($applicationVerificationAccessKey: String!) {
  application: getApplicationByApplicationVerificationAccessKey(
    applicationVerificationAccessKey: $applicationVerificationAccessKey
  ) {
    id
    createdDate
    jsonValue
    withdrawalDate
  }
  verification: getApplicationVerification(
    applicationVerificationAccessKey: $applicationVerificationAccessKey
  ) {
    contactName
    organizationName
    verifiedDate
    rejectedDate
  }
}
    `;

/**
 * __useGetApplicationByApplicationVerificationAccessKeyQuery__
 *
 * To run a query within a React component, call `useGetApplicationByApplicationVerificationAccessKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationByApplicationVerificationAccessKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationByApplicationVerificationAccessKeyQuery({
 *   variables: {
 *      applicationVerificationAccessKey: // value for 'applicationVerificationAccessKey'
 *   },
 * });
 */
export function useGetApplicationByApplicationVerificationAccessKeyQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationByApplicationVerificationAccessKeyQuery, GetApplicationByApplicationVerificationAccessKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationByApplicationVerificationAccessKeyQuery, GetApplicationByApplicationVerificationAccessKeyQueryVariables>(GetApplicationByApplicationVerificationAccessKeyDocument, options);
      }
export function useGetApplicationByApplicationVerificationAccessKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationByApplicationVerificationAccessKeyQuery, GetApplicationByApplicationVerificationAccessKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationByApplicationVerificationAccessKeyQuery, GetApplicationByApplicationVerificationAccessKeyQueryVariables>(GetApplicationByApplicationVerificationAccessKeyDocument, options);
        }
export type GetApplicationByApplicationVerificationAccessKeyQueryHookResult = ReturnType<typeof useGetApplicationByApplicationVerificationAccessKeyQuery>;
export type GetApplicationByApplicationVerificationAccessKeyLazyQueryHookResult = ReturnType<typeof useGetApplicationByApplicationVerificationAccessKeyLazyQuery>;
export type GetApplicationByApplicationVerificationAccessKeyQueryResult = Apollo.QueryResult<GetApplicationByApplicationVerificationAccessKeyQuery, GetApplicationByApplicationVerificationAccessKeyQueryVariables>;
export const GetApplicationByApplicantDocument = gql`
    query getApplicationByApplicant($accessKey: String!) {
  application: getApplicationByApplicant(accessKey: $accessKey) {
    id
    createdDate
    jsonValue
    withdrawalDate
    verifications {
      contactEmailAddress
      organizationName
      verifiedDate
      rejectedDate
    }
  }
}
    `;

/**
 * __useGetApplicationByApplicantQuery__
 *
 * To run a query within a React component, call `useGetApplicationByApplicantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationByApplicantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationByApplicantQuery({
 *   variables: {
 *      accessKey: // value for 'accessKey'
 *   },
 * });
 */
export function useGetApplicationByApplicantQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationByApplicantQuery, GetApplicationByApplicantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationByApplicantQuery, GetApplicationByApplicantQueryVariables>(GetApplicationByApplicantDocument, options);
      }
export function useGetApplicationByApplicantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationByApplicantQuery, GetApplicationByApplicantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationByApplicantQuery, GetApplicationByApplicantQueryVariables>(GetApplicationByApplicantDocument, options);
        }
export type GetApplicationByApplicantQueryHookResult = ReturnType<typeof useGetApplicationByApplicantQuery>;
export type GetApplicationByApplicantLazyQueryHookResult = ReturnType<typeof useGetApplicationByApplicantLazyQuery>;
export type GetApplicationByApplicantQueryResult = Apollo.QueryResult<GetApplicationByApplicantQuery, GetApplicationByApplicantQueryVariables>;
export const GetApplicationsDocument = gql`
    query getApplications($regionId: Int!) {
  applications: getApplications(regionId: $regionId) {
    id
    createdDate
    jsonValue
    withdrawalDate
    note
    cardCreated
    verifications {
      contactEmailAddress
      organizationName
      verifiedDate
      rejectedDate
    }
  }
}
    `;

/**
 * __useGetApplicationsQuery__
 *
 * To run a query within a React component, call `useGetApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useGetApplicationsQuery(baseOptions: Apollo.QueryHookOptions<GetApplicationsQuery, GetApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApplicationsQuery, GetApplicationsQueryVariables>(GetApplicationsDocument, options);
      }
export function useGetApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationsQuery, GetApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApplicationsQuery, GetApplicationsQueryVariables>(GetApplicationsDocument, options);
        }
export type GetApplicationsQueryHookResult = ReturnType<typeof useGetApplicationsQuery>;
export type GetApplicationsLazyQueryHookResult = ReturnType<typeof useGetApplicationsLazyQuery>;
export type GetApplicationsQueryResult = Apollo.QueryResult<GetApplicationsQuery, GetApplicationsQueryVariables>;
export const UpdateApplicationNoteDocument = gql`
    mutation updateApplicationNote($applicationId: Int!, $text: String!) {
  success: updateApplicationNote(applicationId: $applicationId, noteText: $text)
}
    `;
export type UpdateApplicationNoteMutationFn = Apollo.MutationFunction<UpdateApplicationNoteMutation, UpdateApplicationNoteMutationVariables>;

/**
 * __useUpdateApplicationNoteMutation__
 *
 * To run a mutation, you first call `useUpdateApplicationNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApplicationNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApplicationNoteMutation, { data, loading, error }] = useUpdateApplicationNoteMutation({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateApplicationNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicationNoteMutation, UpdateApplicationNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicationNoteMutation, UpdateApplicationNoteMutationVariables>(UpdateApplicationNoteDocument, options);
      }
export type UpdateApplicationNoteMutationHookResult = ReturnType<typeof useUpdateApplicationNoteMutation>;
export type UpdateApplicationNoteMutationResult = Apollo.MutationResult<UpdateApplicationNoteMutation>;
export type UpdateApplicationNoteMutationOptions = Apollo.BaseMutationOptions<UpdateApplicationNoteMutation, UpdateApplicationNoteMutationVariables>;
export const VerifyOrRejectApplicationVerificationDocument = gql`
    mutation verifyOrRejectApplicationVerification($project: String!, $accessKey: String!, $verified: Boolean!) {
  result: verifyOrRejectApplicationVerification(
    project: $project
    accessKey: $accessKey
    verified: $verified
  )
}
    `;
export type VerifyOrRejectApplicationVerificationMutationFn = Apollo.MutationFunction<VerifyOrRejectApplicationVerificationMutation, VerifyOrRejectApplicationVerificationMutationVariables>;

/**
 * __useVerifyOrRejectApplicationVerificationMutation__
 *
 * To run a mutation, you first call `useVerifyOrRejectApplicationVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOrRejectApplicationVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOrRejectApplicationVerificationMutation, { data, loading, error }] = useVerifyOrRejectApplicationVerificationMutation({
 *   variables: {
 *      project: // value for 'project'
 *      accessKey: // value for 'accessKey'
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useVerifyOrRejectApplicationVerificationMutation(baseOptions?: Apollo.MutationHookOptions<VerifyOrRejectApplicationVerificationMutation, VerifyOrRejectApplicationVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyOrRejectApplicationVerificationMutation, VerifyOrRejectApplicationVerificationMutationVariables>(VerifyOrRejectApplicationVerificationDocument, options);
      }
export type VerifyOrRejectApplicationVerificationMutationHookResult = ReturnType<typeof useVerifyOrRejectApplicationVerificationMutation>;
export type VerifyOrRejectApplicationVerificationMutationResult = Apollo.MutationResult<VerifyOrRejectApplicationVerificationMutation>;
export type VerifyOrRejectApplicationVerificationMutationOptions = Apollo.BaseMutationOptions<VerifyOrRejectApplicationVerificationMutation, VerifyOrRejectApplicationVerificationMutationVariables>;
export const WithdrawApplicationDocument = gql`
    mutation withdrawApplication($accessKey: String!) {
  isWithdrawed: withdrawApplication(accessKey: $accessKey)
}
    `;
export type WithdrawApplicationMutationFn = Apollo.MutationFunction<WithdrawApplicationMutation, WithdrawApplicationMutationVariables>;

/**
 * __useWithdrawApplicationMutation__
 *
 * To run a mutation, you first call `useWithdrawApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawApplicationMutation, { data, loading, error }] = useWithdrawApplicationMutation({
 *   variables: {
 *      accessKey: // value for 'accessKey'
 *   },
 * });
 */
export function useWithdrawApplicationMutation(baseOptions?: Apollo.MutationHookOptions<WithdrawApplicationMutation, WithdrawApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WithdrawApplicationMutation, WithdrawApplicationMutationVariables>(WithdrawApplicationDocument, options);
      }
export type WithdrawApplicationMutationHookResult = ReturnType<typeof useWithdrawApplicationMutation>;
export type WithdrawApplicationMutationResult = Apollo.MutationResult<WithdrawApplicationMutation>;
export type WithdrawApplicationMutationOptions = Apollo.BaseMutationOptions<WithdrawApplicationMutation, WithdrawApplicationMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($project: String!, $email: String!, $currentPassword: String!, $newPassword: String!) {
  result: changePassword(
    project: $project
    email: $email
    currentPassword: $currentPassword
    newPassword: $newPassword
  )
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      project: // value for 'project'
 *      email: // value for 'email'
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CheckPasswordResetLinkDocument = gql`
    query checkPasswordResetLink($project: String!, $resetKey: String!) {
  valid: checkPasswordResetLink(project: $project, resetKey: $resetKey)
}
    `;

/**
 * __useCheckPasswordResetLinkQuery__
 *
 * To run a query within a React component, call `useCheckPasswordResetLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPasswordResetLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPasswordResetLinkQuery({
 *   variables: {
 *      project: // value for 'project'
 *      resetKey: // value for 'resetKey'
 *   },
 * });
 */
export function useCheckPasswordResetLinkQuery(baseOptions: Apollo.QueryHookOptions<CheckPasswordResetLinkQuery, CheckPasswordResetLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckPasswordResetLinkQuery, CheckPasswordResetLinkQueryVariables>(CheckPasswordResetLinkDocument, options);
      }
export function useCheckPasswordResetLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckPasswordResetLinkQuery, CheckPasswordResetLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckPasswordResetLinkQuery, CheckPasswordResetLinkQueryVariables>(CheckPasswordResetLinkDocument, options);
        }
export type CheckPasswordResetLinkQueryHookResult = ReturnType<typeof useCheckPasswordResetLinkQuery>;
export type CheckPasswordResetLinkLazyQueryHookResult = ReturnType<typeof useCheckPasswordResetLinkLazyQuery>;
export type CheckPasswordResetLinkQueryResult = Apollo.QueryResult<CheckPasswordResetLinkQuery, CheckPasswordResetLinkQueryVariables>;
export const CreateApiTokenDocument = gql`
    mutation createApiToken($expiresIn: Int!) {
  createApiTokenPayload: createApiToken(expiresIn: $expiresIn)
}
    `;
export type CreateApiTokenMutationFn = Apollo.MutationFunction<CreateApiTokenMutation, CreateApiTokenMutationVariables>;

/**
 * __useCreateApiTokenMutation__
 *
 * To run a mutation, you first call `useCreateApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApiTokenMutation, { data, loading, error }] = useCreateApiTokenMutation({
 *   variables: {
 *      expiresIn: // value for 'expiresIn'
 *   },
 * });
 */
export function useCreateApiTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateApiTokenMutation, CreateApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApiTokenMutation, CreateApiTokenMutationVariables>(CreateApiTokenDocument, options);
      }
export type CreateApiTokenMutationHookResult = ReturnType<typeof useCreateApiTokenMutation>;
export type CreateApiTokenMutationResult = Apollo.MutationResult<CreateApiTokenMutation>;
export type CreateApiTokenMutationOptions = Apollo.BaseMutationOptions<CreateApiTokenMutation, CreateApiTokenMutationVariables>;
export const DeleteApiTokenDocument = gql`
    mutation deleteApiToken($id: Int!) {
  deleteApiToken(id: $id)
}
    `;
export type DeleteApiTokenMutationFn = Apollo.MutationFunction<DeleteApiTokenMutation, DeleteApiTokenMutationVariables>;

/**
 * __useDeleteApiTokenMutation__
 *
 * To run a mutation, you first call `useDeleteApiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiTokenMutation, { data, loading, error }] = useDeleteApiTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApiTokenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApiTokenMutation, DeleteApiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApiTokenMutation, DeleteApiTokenMutationVariables>(DeleteApiTokenDocument, options);
      }
export type DeleteApiTokenMutationHookResult = ReturnType<typeof useDeleteApiTokenMutation>;
export type DeleteApiTokenMutationResult = Apollo.MutationResult<DeleteApiTokenMutation>;
export type DeleteApiTokenMutationOptions = Apollo.BaseMutationOptions<DeleteApiTokenMutation, DeleteApiTokenMutationVariables>;
export const GetApiTokenMetaDataDocument = gql`
    query getApiTokenMetaData {
  tokenMetaData: getApiTokenMetaData {
    id
    creatorEmail
    expirationDate
    type
  }
}
    `;

/**
 * __useGetApiTokenMetaDataQuery__
 *
 * To run a query within a React component, call `useGetApiTokenMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiTokenMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiTokenMetaDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApiTokenMetaDataQuery(baseOptions?: Apollo.QueryHookOptions<GetApiTokenMetaDataQuery, GetApiTokenMetaDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetApiTokenMetaDataQuery, GetApiTokenMetaDataQueryVariables>(GetApiTokenMetaDataDocument, options);
      }
export function useGetApiTokenMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApiTokenMetaDataQuery, GetApiTokenMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetApiTokenMetaDataQuery, GetApiTokenMetaDataQueryVariables>(GetApiTokenMetaDataDocument, options);
        }
export type GetApiTokenMetaDataQueryHookResult = ReturnType<typeof useGetApiTokenMetaDataQuery>;
export type GetApiTokenMetaDataLazyQueryHookResult = ReturnType<typeof useGetApiTokenMetaDataLazyQuery>;
export type GetApiTokenMetaDataQueryResult = Apollo.QueryResult<GetApiTokenMetaDataQuery, GetApiTokenMetaDataQueryVariables>;
export const GetHashingPepperDocument = gql`
    query getHashingPepper {
  pepper: getHashingPepper
}
    `;

/**
 * __useGetHashingPepperQuery__
 *
 * To run a query within a React component, call `useGetHashingPepperQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHashingPepperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHashingPepperQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHashingPepperQuery(baseOptions?: Apollo.QueryHookOptions<GetHashingPepperQuery, GetHashingPepperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHashingPepperQuery, GetHashingPepperQueryVariables>(GetHashingPepperDocument, options);
      }
export function useGetHashingPepperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHashingPepperQuery, GetHashingPepperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHashingPepperQuery, GetHashingPepperQueryVariables>(GetHashingPepperDocument, options);
        }
export type GetHashingPepperQueryHookResult = ReturnType<typeof useGetHashingPepperQuery>;
export type GetHashingPepperLazyQueryHookResult = ReturnType<typeof useGetHashingPepperLazyQuery>;
export type GetHashingPepperQueryResult = Apollo.QueryResult<GetHashingPepperQuery, GetHashingPepperQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($project: String!, $email: String!, $passwordResetKey: String!, $newPassword: String!) {
  result: resetPassword(
    project: $project
    email: $email
    passwordResetKey: $passwordResetKey
    newPassword: $newPassword
  )
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      project: // value for 'project'
 *      email: // value for 'email'
 *      passwordResetKey: // value for 'passwordResetKey'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendResetMailDocument = gql`
    mutation sendResetMail($project: String!, $email: String!) {
  result: sendResetMail(project: $project, email: $email)
}
    `;
export type SendResetMailMutationFn = Apollo.MutationFunction<SendResetMailMutation, SendResetMailMutationVariables>;

/**
 * __useSendResetMailMutation__
 *
 * To run a mutation, you first call `useSendResetMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetMailMutation, { data, loading, error }] = useSendResetMailMutation({
 *   variables: {
 *      project: // value for 'project'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetMailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetMailMutation, SendResetMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetMailMutation, SendResetMailMutationVariables>(SendResetMailDocument, options);
      }
export type SendResetMailMutationHookResult = ReturnType<typeof useSendResetMailMutation>;
export type SendResetMailMutationResult = Apollo.MutationResult<SendResetMailMutation>;
export type SendResetMailMutationOptions = Apollo.BaseMutationOptions<SendResetMailMutation, SendResetMailMutationVariables>;
export const SignInDocument = gql`
    mutation signIn($project: String!, $authData: AuthDataInput!) {
  signInPayload: signIn(project: $project, authData: $authData) {
    token
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      project: // value for 'project'
 *      authData: // value for 'authData'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const WhoAmIDocument = gql`
    query whoAmI($project: String!) {
  me: whoAmI(project: $project) {
    id
    email
    role
    region {
      id
      name
      prefix
      activatedForApplication
      activatedForCardConfirmationMail
    }
  }
}
    `;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;
export const CreateCardsDocument = gql`
    mutation createCards($project: String!, $encodedCardInfos: [String!]!, $generateStaticCodes: Boolean!, $applicationIdToMarkAsProcessed: Int) {
  cards: createCardsByCardInfos(
    project: $project
    encodedCardInfos: $encodedCardInfos
    generateStaticCodes: $generateStaticCodes
    applicationIdToMarkAsProcessed: $applicationIdToMarkAsProcessed
  ) {
    dynamicActivationCode {
      cardInfoHashBase64
      codeBase64
    }
    staticVerificationCode {
      cardInfoHashBase64
      codeBase64
    }
  }
}
    `;
export type CreateCardsMutationFn = Apollo.MutationFunction<CreateCardsMutation, CreateCardsMutationVariables>;

/**
 * __useCreateCardsMutation__
 *
 * To run a mutation, you first call `useCreateCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardsMutation, { data, loading, error }] = useCreateCardsMutation({
 *   variables: {
 *      project: // value for 'project'
 *      encodedCardInfos: // value for 'encodedCardInfos'
 *      generateStaticCodes: // value for 'generateStaticCodes'
 *      applicationIdToMarkAsProcessed: // value for 'applicationIdToMarkAsProcessed'
 *   },
 * });
 */
export function useCreateCardsMutation(baseOptions?: Apollo.MutationHookOptions<CreateCardsMutation, CreateCardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCardsMutation, CreateCardsMutationVariables>(CreateCardsDocument, options);
      }
export type CreateCardsMutationHookResult = ReturnType<typeof useCreateCardsMutation>;
export type CreateCardsMutationResult = Apollo.MutationResult<CreateCardsMutation>;
export type CreateCardsMutationOptions = Apollo.BaseMutationOptions<CreateCardsMutation, CreateCardsMutationVariables>;
export const CreateCardsFromSelfServiceDocument = gql`
    mutation createCardsFromSelfService($project: String!, $encodedCardInfo: String!, $generateStaticCodes: Boolean!) {
  card: createCardFromSelfService(
    project: $project
    encodedCardInfo: $encodedCardInfo
    generateStaticCode: $generateStaticCodes
  ) {
    dynamicActivationCode {
      cardInfoHashBase64
      codeBase64
    }
    staticVerificationCode {
      cardInfoHashBase64
      codeBase64
    }
  }
}
    `;
export type CreateCardsFromSelfServiceMutationFn = Apollo.MutationFunction<CreateCardsFromSelfServiceMutation, CreateCardsFromSelfServiceMutationVariables>;

/**
 * __useCreateCardsFromSelfServiceMutation__
 *
 * To run a mutation, you first call `useCreateCardsFromSelfServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardsFromSelfServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardsFromSelfServiceMutation, { data, loading, error }] = useCreateCardsFromSelfServiceMutation({
 *   variables: {
 *      project: // value for 'project'
 *      encodedCardInfo: // value for 'encodedCardInfo'
 *      generateStaticCodes: // value for 'generateStaticCodes'
 *   },
 * });
 */
export function useCreateCardsFromSelfServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateCardsFromSelfServiceMutation, CreateCardsFromSelfServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCardsFromSelfServiceMutation, CreateCardsFromSelfServiceMutationVariables>(CreateCardsFromSelfServiceDocument, options);
      }
export type CreateCardsFromSelfServiceMutationHookResult = ReturnType<typeof useCreateCardsFromSelfServiceMutation>;
export type CreateCardsFromSelfServiceMutationResult = Apollo.MutationResult<CreateCardsFromSelfServiceMutation>;
export type CreateCardsFromSelfServiceMutationOptions = Apollo.BaseMutationOptions<CreateCardsFromSelfServiceMutation, CreateCardsFromSelfServiceMutationVariables>;
export const DeleteCardsDocument = gql`
    mutation deleteCards($regionId: Int!, $cardInfoHashBase64List: [String!]!) {
  deleteInactiveCards(
    regionId: $regionId
    cardInfoHashBase64List: $cardInfoHashBase64List
  )
}
    `;
export type DeleteCardsMutationFn = Apollo.MutationFunction<DeleteCardsMutation, DeleteCardsMutationVariables>;

/**
 * __useDeleteCardsMutation__
 *
 * To run a mutation, you first call `useDeleteCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardsMutation, { data, loading, error }] = useDeleteCardsMutation({
 *   variables: {
 *      regionId: // value for 'regionId'
 *      cardInfoHashBase64List: // value for 'cardInfoHashBase64List'
 *   },
 * });
 */
export function useDeleteCardsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardsMutation, DeleteCardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardsMutation, DeleteCardsMutationVariables>(DeleteCardsDocument, options);
      }
export type DeleteCardsMutationHookResult = ReturnType<typeof useDeleteCardsMutation>;
export type DeleteCardsMutationResult = Apollo.MutationResult<DeleteCardsMutation>;
export type DeleteCardsMutationOptions = Apollo.BaseMutationOptions<DeleteCardsMutation, DeleteCardsMutationVariables>;
export const GetCardStatisticsInProjectDocument = gql`
    query getCardStatisticsInProject($projectId: String!, $dateStart: String!, $dateEnd: String!) {
  result: getCardStatisticsInProject(
    project: $projectId
    dateStart: $dateStart
    dateEnd: $dateEnd
  ) {
    region
    cardsCreated
    cardsActivated
  }
}
    `;

/**
 * __useGetCardStatisticsInProjectQuery__
 *
 * To run a query within a React component, call `useGetCardStatisticsInProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardStatisticsInProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardStatisticsInProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *   },
 * });
 */
export function useGetCardStatisticsInProjectQuery(baseOptions: Apollo.QueryHookOptions<GetCardStatisticsInProjectQuery, GetCardStatisticsInProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardStatisticsInProjectQuery, GetCardStatisticsInProjectQueryVariables>(GetCardStatisticsInProjectDocument, options);
      }
export function useGetCardStatisticsInProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardStatisticsInProjectQuery, GetCardStatisticsInProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardStatisticsInProjectQuery, GetCardStatisticsInProjectQueryVariables>(GetCardStatisticsInProjectDocument, options);
        }
export type GetCardStatisticsInProjectQueryHookResult = ReturnType<typeof useGetCardStatisticsInProjectQuery>;
export type GetCardStatisticsInProjectLazyQueryHookResult = ReturnType<typeof useGetCardStatisticsInProjectLazyQuery>;
export type GetCardStatisticsInProjectQueryResult = Apollo.QueryResult<GetCardStatisticsInProjectQuery, GetCardStatisticsInProjectQueryVariables>;
export const GetCardStatisticsInRegionDocument = gql`
    query getCardStatisticsInRegion($projectId: String!, $regionId: Int!, $dateStart: String!, $dateEnd: String!) {
  result: getCardStatisticsInRegion(
    project: $projectId
    regionId: $regionId
    dateStart: $dateStart
    dateEnd: $dateEnd
  ) {
    region
    cardsCreated
    cardsActivated
  }
}
    `;

/**
 * __useGetCardStatisticsInRegionQuery__
 *
 * To run a query within a React component, call `useGetCardStatisticsInRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardStatisticsInRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardStatisticsInRegionQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      regionId: // value for 'regionId'
 *      dateStart: // value for 'dateStart'
 *      dateEnd: // value for 'dateEnd'
 *   },
 * });
 */
export function useGetCardStatisticsInRegionQuery(baseOptions: Apollo.QueryHookOptions<GetCardStatisticsInRegionQuery, GetCardStatisticsInRegionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardStatisticsInRegionQuery, GetCardStatisticsInRegionQueryVariables>(GetCardStatisticsInRegionDocument, options);
      }
export function useGetCardStatisticsInRegionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardStatisticsInRegionQuery, GetCardStatisticsInRegionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardStatisticsInRegionQuery, GetCardStatisticsInRegionQueryVariables>(GetCardStatisticsInRegionDocument, options);
        }
export type GetCardStatisticsInRegionQueryHookResult = ReturnType<typeof useGetCardStatisticsInRegionQuery>;
export type GetCardStatisticsInRegionLazyQueryHookResult = ReturnType<typeof useGetCardStatisticsInRegionLazyQuery>;
export type GetCardStatisticsInRegionQueryResult = Apollo.QueryResult<GetCardStatisticsInRegionQuery, GetCardStatisticsInRegionQueryVariables>;
export const SendCardCreationConfirmationMailDocument = gql`
    mutation sendCardCreationConfirmationMail($project: String!, $regionId: Int!, $recipientAddress: String!, $recipientName: String!, $deepLink: String!) {
  result: sendCardCreationConfirmationMail(
    project: $project
    regionId: $regionId
    recipientAddress: $recipientAddress
    recipientName: $recipientName
    deepLink: $deepLink
  )
}
    `;
export type SendCardCreationConfirmationMailMutationFn = Apollo.MutationFunction<SendCardCreationConfirmationMailMutation, SendCardCreationConfirmationMailMutationVariables>;

/**
 * __useSendCardCreationConfirmationMailMutation__
 *
 * To run a mutation, you first call `useSendCardCreationConfirmationMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCardCreationConfirmationMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCardCreationConfirmationMailMutation, { data, loading, error }] = useSendCardCreationConfirmationMailMutation({
 *   variables: {
 *      project: // value for 'project'
 *      regionId: // value for 'regionId'
 *      recipientAddress: // value for 'recipientAddress'
 *      recipientName: // value for 'recipientName'
 *      deepLink: // value for 'deepLink'
 *   },
 * });
 */
export function useSendCardCreationConfirmationMailMutation(baseOptions?: Apollo.MutationHookOptions<SendCardCreationConfirmationMailMutation, SendCardCreationConfirmationMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCardCreationConfirmationMailMutation, SendCardCreationConfirmationMailMutationVariables>(SendCardCreationConfirmationMailDocument, options);
      }
export type SendCardCreationConfirmationMailMutationHookResult = ReturnType<typeof useSendCardCreationConfirmationMailMutation>;
export type SendCardCreationConfirmationMailMutationResult = Apollo.MutationResult<SendCardCreationConfirmationMailMutation>;
export type SendCardCreationConfirmationMailMutationOptions = Apollo.BaseMutationOptions<SendCardCreationConfirmationMailMutation, SendCardCreationConfirmationMailMutationVariables>;
export const GetDataPolicyDocument = gql`
    query getDataPolicy($regionId: Int!) {
  dataPolicy: regionByRegionId(regionId: $regionId) {
    dataPrivacyPolicy
  }
}
    `;

/**
 * __useGetDataPolicyQuery__
 *
 * To run a query within a React component, call `useGetDataPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataPolicyQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useGetDataPolicyQuery(baseOptions: Apollo.QueryHookOptions<GetDataPolicyQuery, GetDataPolicyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataPolicyQuery, GetDataPolicyQueryVariables>(GetDataPolicyDocument, options);
      }
export function useGetDataPolicyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataPolicyQuery, GetDataPolicyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataPolicyQuery, GetDataPolicyQueryVariables>(GetDataPolicyDocument, options);
        }
export type GetDataPolicyQueryHookResult = ReturnType<typeof useGetDataPolicyQuery>;
export type GetDataPolicyLazyQueryHookResult = ReturnType<typeof useGetDataPolicyLazyQuery>;
export type GetDataPolicyQueryResult = Apollo.QueryResult<GetDataPolicyQuery, GetDataPolicyQueryVariables>;
export const GetRegionSettingsByIdDocument = gql`
    query getRegionSettingsById($regionId: Int!) {
  result: regionByRegionId(regionId: $regionId) {
    activatedForApplication
    activatedForCardConfirmationMail
  }
}
    `;

/**
 * __useGetRegionSettingsByIdQuery__
 *
 * To run a query within a React component, call `useGetRegionSettingsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionSettingsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionSettingsByIdQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useGetRegionSettingsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetRegionSettingsByIdQuery, GetRegionSettingsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegionSettingsByIdQuery, GetRegionSettingsByIdQueryVariables>(GetRegionSettingsByIdDocument, options);
      }
export function useGetRegionSettingsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionSettingsByIdQuery, GetRegionSettingsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegionSettingsByIdQuery, GetRegionSettingsByIdQueryVariables>(GetRegionSettingsByIdDocument, options);
        }
export type GetRegionSettingsByIdQueryHookResult = ReturnType<typeof useGetRegionSettingsByIdQuery>;
export type GetRegionSettingsByIdLazyQueryHookResult = ReturnType<typeof useGetRegionSettingsByIdLazyQuery>;
export type GetRegionSettingsByIdQueryResult = Apollo.QueryResult<GetRegionSettingsByIdQuery, GetRegionSettingsByIdQueryVariables>;
export const GetRegionsDocument = gql`
    query getRegions($project: String!) {
  regions: regionsInProject(project: $project) {
    id
    prefix
    name
    regionIdentifier
    activatedForApplication
    activatedForCardConfirmationMail
  }
}
    `;

/**
 * __useGetRegionsQuery__
 *
 * To run a query within a React component, call `useGetRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsQuery({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useGetRegionsQuery(baseOptions: Apollo.QueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, options);
      }
export function useGetRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionsQuery, GetRegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegionsQuery, GetRegionsQueryVariables>(GetRegionsDocument, options);
        }
export type GetRegionsQueryHookResult = ReturnType<typeof useGetRegionsQuery>;
export type GetRegionsLazyQueryHookResult = ReturnType<typeof useGetRegionsLazyQuery>;
export type GetRegionsQueryResult = Apollo.QueryResult<GetRegionsQuery, GetRegionsQueryVariables>;
export const GetRegionsByPostalCodeDocument = gql`
    query getRegionsByPostalCode($postalCode: String!, $project: String!) {
  regions: regionsByPostalCode(postalCode: $postalCode, project: $project) {
    id
    name
    prefix
    activatedForApplication
    activatedForCardConfirmationMail
  }
}
    `;

/**
 * __useGetRegionsByPostalCodeQuery__
 *
 * To run a query within a React component, call `useGetRegionsByPostalCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsByPostalCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsByPostalCodeQuery({
 *   variables: {
 *      postalCode: // value for 'postalCode'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useGetRegionsByPostalCodeQuery(baseOptions: Apollo.QueryHookOptions<GetRegionsByPostalCodeQuery, GetRegionsByPostalCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegionsByPostalCodeQuery, GetRegionsByPostalCodeQueryVariables>(GetRegionsByPostalCodeDocument, options);
      }
export function useGetRegionsByPostalCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionsByPostalCodeQuery, GetRegionsByPostalCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegionsByPostalCodeQuery, GetRegionsByPostalCodeQueryVariables>(GetRegionsByPostalCodeDocument, options);
        }
export type GetRegionsByPostalCodeQueryHookResult = ReturnType<typeof useGetRegionsByPostalCodeQuery>;
export type GetRegionsByPostalCodeLazyQueryHookResult = ReturnType<typeof useGetRegionsByPostalCodeLazyQuery>;
export type GetRegionsByPostalCodeQueryResult = Apollo.QueryResult<GetRegionsByPostalCodeQuery, GetRegionsByPostalCodeQueryVariables>;
export const UpdateDataPolicyDocument = gql`
    mutation updateDataPolicy($regionId: Int!, $text: String!) {
  dataPolicy: updateDataPrivacy(regionId: $regionId, dataPrivacyText: $text)
}
    `;
export type UpdateDataPolicyMutationFn = Apollo.MutationFunction<UpdateDataPolicyMutation, UpdateDataPolicyMutationVariables>;

/**
 * __useUpdateDataPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateDataPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataPolicyMutation, { data, loading, error }] = useUpdateDataPolicyMutation({
 *   variables: {
 *      regionId: // value for 'regionId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateDataPolicyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataPolicyMutation, UpdateDataPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDataPolicyMutation, UpdateDataPolicyMutationVariables>(UpdateDataPolicyDocument, options);
      }
export type UpdateDataPolicyMutationHookResult = ReturnType<typeof useUpdateDataPolicyMutation>;
export type UpdateDataPolicyMutationResult = Apollo.MutationResult<UpdateDataPolicyMutation>;
export type UpdateDataPolicyMutationOptions = Apollo.BaseMutationOptions<UpdateDataPolicyMutation, UpdateDataPolicyMutationVariables>;
export const UpdateRegionSettingsDocument = gql`
    mutation updateRegionSettings($regionId: Int!, $activatedForApplication: Boolean!, $activatedForConfirmationMail: Boolean!) {
  result: updateRegionSettings(
    regionId: $regionId
    activatedForApplication: $activatedForApplication
    activatedForConfirmationMail: $activatedForConfirmationMail
  )
}
    `;
export type UpdateRegionSettingsMutationFn = Apollo.MutationFunction<UpdateRegionSettingsMutation, UpdateRegionSettingsMutationVariables>;

/**
 * __useUpdateRegionSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateRegionSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegionSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegionSettingsMutation, { data, loading, error }] = useUpdateRegionSettingsMutation({
 *   variables: {
 *      regionId: // value for 'regionId'
 *      activatedForApplication: // value for 'activatedForApplication'
 *      activatedForConfirmationMail: // value for 'activatedForConfirmationMail'
 *   },
 * });
 */
export function useUpdateRegionSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRegionSettingsMutation, UpdateRegionSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRegionSettingsMutation, UpdateRegionSettingsMutationVariables>(UpdateRegionSettingsDocument, options);
      }
export type UpdateRegionSettingsMutationHookResult = ReturnType<typeof useUpdateRegionSettingsMutation>;
export type UpdateRegionSettingsMutationResult = Apollo.MutationResult<UpdateRegionSettingsMutation>;
export type UpdateRegionSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateRegionSettingsMutation, UpdateRegionSettingsMutationVariables>;
export const ImportAcceptingStoresDocument = gql`
    mutation importAcceptingStores($stores: [CSVAcceptingStoreInput!]!, $project: String!, $dryRun: Boolean!) {
  result: importAcceptingStores(
    stores: $stores
    project: $project
    dryRun: $dryRun
  ) {
    storesCreated
    storesDeleted
    storesUntouched
  }
}
    `;
export type ImportAcceptingStoresMutationFn = Apollo.MutationFunction<ImportAcceptingStoresMutation, ImportAcceptingStoresMutationVariables>;

/**
 * __useImportAcceptingStoresMutation__
 *
 * To run a mutation, you first call `useImportAcceptingStoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportAcceptingStoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importAcceptingStoresMutation, { data, loading, error }] = useImportAcceptingStoresMutation({
 *   variables: {
 *      stores: // value for 'stores'
 *      project: // value for 'project'
 *      dryRun: // value for 'dryRun'
 *   },
 * });
 */
export function useImportAcceptingStoresMutation(baseOptions?: Apollo.MutationHookOptions<ImportAcceptingStoresMutation, ImportAcceptingStoresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportAcceptingStoresMutation, ImportAcceptingStoresMutationVariables>(ImportAcceptingStoresDocument, options);
      }
export type ImportAcceptingStoresMutationHookResult = ReturnType<typeof useImportAcceptingStoresMutation>;
export type ImportAcceptingStoresMutationResult = Apollo.MutationResult<ImportAcceptingStoresMutation>;
export type ImportAcceptingStoresMutationOptions = Apollo.BaseMutationOptions<ImportAcceptingStoresMutation, ImportAcceptingStoresMutationVariables>;
export const CreateAdministratorDocument = gql`
    mutation createAdministrator($project: String!, $email: String!, $regionId: Int, $role: Role!, $sendWelcomeMail: Boolean!) {
  result: createAdministrator(
    project: $project
    email: $email
    regionId: $regionId
    role: $role
    sendWelcomeMail: $sendWelcomeMail
  )
}
    `;
export type CreateAdministratorMutationFn = Apollo.MutationFunction<CreateAdministratorMutation, CreateAdministratorMutationVariables>;

/**
 * __useCreateAdministratorMutation__
 *
 * To run a mutation, you first call `useCreateAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdministratorMutation, { data, loading, error }] = useCreateAdministratorMutation({
 *   variables: {
 *      project: // value for 'project'
 *      email: // value for 'email'
 *      regionId: // value for 'regionId'
 *      role: // value for 'role'
 *      sendWelcomeMail: // value for 'sendWelcomeMail'
 *   },
 * });
 */
export function useCreateAdministratorMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdministratorMutation, CreateAdministratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdministratorMutation, CreateAdministratorMutationVariables>(CreateAdministratorDocument, options);
      }
export type CreateAdministratorMutationHookResult = ReturnType<typeof useCreateAdministratorMutation>;
export type CreateAdministratorMutationResult = Apollo.MutationResult<CreateAdministratorMutation>;
export type CreateAdministratorMutationOptions = Apollo.BaseMutationOptions<CreateAdministratorMutation, CreateAdministratorMutationVariables>;
export const DeleteAdministratorDocument = gql`
    mutation deleteAdministrator($project: String!, $adminId: Int!) {
  result: deleteAdministrator(project: $project, adminId: $adminId)
}
    `;
export type DeleteAdministratorMutationFn = Apollo.MutationFunction<DeleteAdministratorMutation, DeleteAdministratorMutationVariables>;

/**
 * __useDeleteAdministratorMutation__
 *
 * To run a mutation, you first call `useDeleteAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdministratorMutation, { data, loading, error }] = useDeleteAdministratorMutation({
 *   variables: {
 *      project: // value for 'project'
 *      adminId: // value for 'adminId'
 *   },
 * });
 */
export function useDeleteAdministratorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdministratorMutation, DeleteAdministratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdministratorMutation, DeleteAdministratorMutationVariables>(DeleteAdministratorDocument, options);
      }
export type DeleteAdministratorMutationHookResult = ReturnType<typeof useDeleteAdministratorMutation>;
export type DeleteAdministratorMutationResult = Apollo.MutationResult<DeleteAdministratorMutation>;
export type DeleteAdministratorMutationOptions = Apollo.BaseMutationOptions<DeleteAdministratorMutation, DeleteAdministratorMutationVariables>;
export const EditAdministratorDocument = gql`
    mutation editAdministrator($project: String!, $adminId: Int!, $newEmail: String!, $newRegionId: Int, $newRole: Role!) {
  result: editAdministrator(
    project: $project
    adminId: $adminId
    newEmail: $newEmail
    newRegionId: $newRegionId
    newRole: $newRole
  )
}
    `;
export type EditAdministratorMutationFn = Apollo.MutationFunction<EditAdministratorMutation, EditAdministratorMutationVariables>;

/**
 * __useEditAdministratorMutation__
 *
 * To run a mutation, you first call `useEditAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAdministratorMutation, { data, loading, error }] = useEditAdministratorMutation({
 *   variables: {
 *      project: // value for 'project'
 *      adminId: // value for 'adminId'
 *      newEmail: // value for 'newEmail'
 *      newRegionId: // value for 'newRegionId'
 *      newRole: // value for 'newRole'
 *   },
 * });
 */
export function useEditAdministratorMutation(baseOptions?: Apollo.MutationHookOptions<EditAdministratorMutation, EditAdministratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAdministratorMutation, EditAdministratorMutationVariables>(EditAdministratorDocument, options);
      }
export type EditAdministratorMutationHookResult = ReturnType<typeof useEditAdministratorMutation>;
export type EditAdministratorMutationResult = Apollo.MutationResult<EditAdministratorMutation>;
export type EditAdministratorMutationOptions = Apollo.BaseMutationOptions<EditAdministratorMutation, EditAdministratorMutationVariables>;
export const GetNotificationSettingsDocument = gql`
    query getNotificationSettings($project: String!) {
  notificationSettings: getNotificationSettings(project: $project) {
    notificationOnApplication
    notificationOnVerification
  }
}
    `;

/**
 * __useGetNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useGetNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationSettingsQuery({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useGetNotificationSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>(GetNotificationSettingsDocument, options);
      }
export function useGetNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>(GetNotificationSettingsDocument, options);
        }
export type GetNotificationSettingsQueryHookResult = ReturnType<typeof useGetNotificationSettingsQuery>;
export type GetNotificationSettingsLazyQueryHookResult = ReturnType<typeof useGetNotificationSettingsLazyQuery>;
export type GetNotificationSettingsQueryResult = Apollo.QueryResult<GetNotificationSettingsQuery, GetNotificationSettingsQueryVariables>;
export const GetUsersInProjectDocument = gql`
    query getUsersInProject($project: String!) {
  users: getUsersInProject(project: $project) {
    regionId
    email
    id
    role
  }
}
    `;

/**
 * __useGetUsersInProjectQuery__
 *
 * To run a query within a React component, call `useGetUsersInProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersInProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersInProjectQuery({
 *   variables: {
 *      project: // value for 'project'
 *   },
 * });
 */
export function useGetUsersInProjectQuery(baseOptions: Apollo.QueryHookOptions<GetUsersInProjectQuery, GetUsersInProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersInProjectQuery, GetUsersInProjectQueryVariables>(GetUsersInProjectDocument, options);
      }
export function useGetUsersInProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersInProjectQuery, GetUsersInProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersInProjectQuery, GetUsersInProjectQueryVariables>(GetUsersInProjectDocument, options);
        }
export type GetUsersInProjectQueryHookResult = ReturnType<typeof useGetUsersInProjectQuery>;
export type GetUsersInProjectLazyQueryHookResult = ReturnType<typeof useGetUsersInProjectLazyQuery>;
export type GetUsersInProjectQueryResult = Apollo.QueryResult<GetUsersInProjectQuery, GetUsersInProjectQueryVariables>;
export const GetUsersInRegionDocument = gql`
    query getUsersInRegion($regionId: Int!) {
  users: getUsersInRegion(regionId: $regionId) {
    regionId
    email
    id
    role
  }
}
    `;

/**
 * __useGetUsersInRegionQuery__
 *
 * To run a query within a React component, call `useGetUsersInRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersInRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersInRegionQuery({
 *   variables: {
 *      regionId: // value for 'regionId'
 *   },
 * });
 */
export function useGetUsersInRegionQuery(baseOptions: Apollo.QueryHookOptions<GetUsersInRegionQuery, GetUsersInRegionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersInRegionQuery, GetUsersInRegionQueryVariables>(GetUsersInRegionDocument, options);
      }
export function useGetUsersInRegionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersInRegionQuery, GetUsersInRegionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersInRegionQuery, GetUsersInRegionQueryVariables>(GetUsersInRegionDocument, options);
        }
export type GetUsersInRegionQueryHookResult = ReturnType<typeof useGetUsersInRegionQuery>;
export type GetUsersInRegionLazyQueryHookResult = ReturnType<typeof useGetUsersInRegionLazyQuery>;
export type GetUsersInRegionQueryResult = Apollo.QueryResult<GetUsersInRegionQuery, GetUsersInRegionQueryVariables>;
export const UpdateNotificationSettingsDocument = gql`
    mutation updateNotificationSettings($project: String!, $notificationSettings: NotificationSettingsInput!) {
  updateNotificationSettings(
    project: $project
    notificationSettings: $notificationSettings
  )
}
    `;
export type UpdateNotificationSettingsMutationFn = Apollo.MutationFunction<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>;

/**
 * __useUpdateNotificationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingsMutation, { data, loading, error }] = useUpdateNotificationSettingsMutation({
 *   variables: {
 *      project: // value for 'project'
 *      notificationSettings: // value for 'notificationSettings'
 *   },
 * });
 */
export function useUpdateNotificationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>(UpdateNotificationSettingsDocument, options);
      }
export type UpdateNotificationSettingsMutationHookResult = ReturnType<typeof useUpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationResult = Apollo.MutationResult<UpdateNotificationSettingsMutation>;
export type UpdateNotificationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>;